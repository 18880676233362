import { defineStore } from 'pinia'
import state from '@/stores/user/state'
import actionsStore from '@/stores/user/actions'
import gettersStore from '@/stores/user/getters'
import mutationsStore from '@/stores/user/mutations'

export const useUserStore = defineStore('user', () => {
  const getters = gettersStore(state)
  const mutations = mutationsStore(state)
  const actions = actionsStore(mutations)
  return {
    state,
    getters,
    mutations,
    actions,
  }
})
