import { defineStore } from 'pinia'
import gettersStore from '@/stores/crawler/getters'
import state from '@/stores/crawler/state'
import mutationsStore from '@/stores/crawler/mutations'
import actionsStore from '@/stores/crawler/actions'

export const useCrawlerStore = defineStore('crawlerStore', () => {
  const getters = gettersStore(state)
  const mutations = mutationsStore(state)
  const actions = actionsStore(mutations)

  return {
    state,
    getters,
    mutations,
    actions,
  }
})
