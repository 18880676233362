<template>
  <div class="add-url">
    <div v-for="(url, index) in urls" :key="index" class="add-url__fields">
      <ChInput
        v-model="urls[index].url"
        class="add-url__field"
        placeholder="Enter URL"
        prepend-icon="link"
        :error="urls[index].error"
      />
      <ChButton
        class="add-url__icon"
        icon="delete"
        :disabled="urls.length === 1"
        :variant="ButtonVariant.CLEAR"
        :color="ColorScheme.ALERT"
        :size="ButtonSize.SM"
        @click="deleteUrl(index)"
      />
    </div>
    <ChButton
      class="add-url__icon"
      icon="add"
      :variant="ButtonVariant.CLEAR"
      :size="ButtonSize.SM"
      @click="addNewUrl"
    />
    <ChButton
      class="add-url__btn ml-auto"
      prepend-icon="dismiss"
      :color="ColorScheme.SECONDARY"
      :size="ButtonSize.SM"
      @click="cancel"
      >Cancel</ChButton
    >
    <ChButton
      class="add-url__btn ml-2"
      prepend-icon="add"
      :color="ColorScheme.PRIMARY"
      :size="ButtonSize.SM"
      :disabled="!isValid"
      @click="addUrls"
      >Add URLs</ChButton
    >
  </div>
</template>

<script setup lang="ts">
  import {
    ButtonSize,
    ButtonVariant,
    ChButton,
    ChInput,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'
  import { computed, reactive, ref, watch } from 'vue'
  import { useValidation } from '@/composables/useValidation'
  import { useCrawlerStore } from '@/stores/crawler'

  interface Url {
    url: string
    error: string
  }

  const { isValidUrl } = useValidation()
  const crawlerStore = useCrawlerStore()

  const emit = defineEmits(['add', 'cancel'])

  const urls = reactive<Url[]>([
    {
      url: '',
      error: '',
    },
  ])
  const isValid = ref<boolean>(true)

  const urlsTypes = computed(() => crawlerStore.getters.urlsTypes())

  const addNewUrl = () => {
    urls.push({
      url: '',
      error: '',
    })
  }

  const deleteUrl = (index: number) => {
    urls.splice(index, 1)
  }

  const cancel = () => {
    emit('cancel')
  }

  const addUrls = () => {
    emit(
      'add',
      urls
        .filter((item: Url) => item.url.length > 0)
        .map((item: Url) => item.url),
    )
  }

  watch(
    urls,
    (newUrls) => {
      isValid.value = newUrls.every(
        (item) => item.url && isValidUrl(item.url) && !item.error,
      )
      if (urlsTypes.value.duplicateURLs.length > 0) {
        urls.forEach((item) => {
          if (urlsTypes.value.duplicateURLs.includes(item.url)) {
            item.error = 'This URL already exists'
          } else {
            item.error = ''
          }
        })
      }
      if (urlsTypes.value.invalidURLs.length > 0) {
        urls.forEach((item) => {
          if (urlsTypes.value.invalidURLs.includes(item.url)) {
            item.error = 'Invalid URL'
          } else {
            item.error = ''
          }
        })
      }
    },
    {
      immediate: true,
      deep: true,
    },
  )

  watch(
    urlsTypes,
    (newUrlsTypes) => {
      if (newUrlsTypes.duplicateURLs.length > 0) {
        urls.forEach((item) => {
          if (newUrlsTypes.duplicateURLs.includes(item.url)) {
            item.error = 'This URL already exists'
          } else {
            item.error = ''
          }
        })
      }
      if (newUrlsTypes.invalidURLs.length > 0) {
        urls.forEach((item) => {
          if (newUrlsTypes.invalidURLs.includes(item.url)) {
            item.error = 'Invalid URL'
          } else {
            item.error = ''
          }
        })
      }
      if (newUrlsTypes.webpages.length > 0) {
        const mappedWebpages = newUrlsTypes.webpages.map(
          (item) => item.dataSource.source,
        )
        urls.forEach((item, index) => {
          if (mappedWebpages.includes(item.url)) {
            urls.splice(index, 1)
          }
        })
      }
    },
    {
      deep: true,
    },
  )
</script>

<style scoped lang="scss">
  .add-url {
    @apply flex items-center flex-wrap p-4;

    &__fields {
      @apply flex items-center mb-4 w-full;
    }

    &__field {
      @apply flex-1 mr-2;
    }
  }
</style>
