export default (state: any) => {
  const setUsers = (payload: any) => {
    state.users = payload
  }
  const setUser = (payload: any) => {
    state.user = payload
    setFetched(true)
  }

  const setSelected = (payload: any) => {
    state.selected = payload
  }

  const setFetched = (payload: boolean) => {
    state.fetched = payload
  }

  return {
    setUsers,
    setUser,
    setSelected,
    setFetched,
  }
}
