export default (state: any) => {
  const nests = () => {
    return state.nests.items
  }

  const nest = () => {
    return state.nest
  }

  const createdNest = () => {
    return state.created
  }

  const nestOptions = () => {
    return state.options
  }

  const secret = () => {
    return state.secret
  }

  const flags = () => {
    return state.flags
  }

  const flagLoading = () => {
    return state.flags.loading
  }

  return {
    nests,
    nest,
    createdNest,
    nestOptions,
    secret,
    flags,
    flagLoading,
  }
}
