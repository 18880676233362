<template>
  <div
    class="accordion flex flex-col relative bg-white dark:bg-gray-900 rounded"
  >
    <div
      class="accordion__header flex items-center justify-between px-4 py-2 cursor-pointer"
      @click="toggleAccordion"
    >
      <span class="accordion__title">{{ title }}</span>
      <ai-icon
        :class="{ expanded: modelValue }"
        class="accordion__icon"
        size="16"
        icon="chevron"
      />
    </div>
    <div :class="{ active: modelValue }" class="accordion__content w-full">
      <div class="p-4">
        <div v-if="content">{{ content }}</div>
        <slot v-else />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import AiIcon from '@/components/app/global/AiIcon.vue'

  const modelValue = defineModel()
  defineProps({
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  })

  const emits = defineEmits(['toggle'])

  const toggleAccordion = () => {
    emits('toggle', !modelValue)
  }
</script>

<style scoped lang="scss">
  .accordion {
    &__icon {
      transition: transform 0.1s ease-in-out;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    &__title {
      @apply text-gray-900 dark:text-white;
    }

    &__content {
      max-height: 0;
      opacity: 0;
      transition:
        all 0.25s ease-in-out,
        opacity 0.25s ease-in-out 0.25s;
      overflow: hidden;

      &.active {
        max-height: 1000px;

        @apply opacity-100 overflow-y-auto;
      }
    }
  }
</style>
