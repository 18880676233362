import { useRoute } from 'vue-router'
import { computed } from 'vue'

export function useRouteParams() {
  const route = useRoute()

  const query = computed(() => route.query)
  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  return { query, params: route.params, orgId, nestId }
}
