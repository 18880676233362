<template>
  <div
    class="editable-title flex items-center w-full gap-1 mb-1"
    @mouseenter="showEdit"
    @mouseleave="hideEdit"
  >
    <span v-resize-observer="onResize" class="editable-title__hidden-text">{{
      model
    }}</span>
    <input
      v-model="model"
      :disabled="!enabled"
      :style="{ width: titleWidth + 'px' }"
      :class="[enabled ? 'dark:border-gray-500' : 'border-transparent']"
      class="editable-title__field px-1 -ml-1 border focus:border-woot-600 rounded h-8 bg-transparent dark:text-white focus:bg-woot-50 dark:focus:bg-woot-800"
      type="text"
    />
    <ChButton
      v-if="!enabled && editBtn"
      icon="edit"
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      :size="ButtonSize.SM"
      @click="toggleEdit"
    />
    <ChButton
      v-if="enabled"
      :size="ButtonSize.SM"
      :color="ColorScheme.SUCCESS"
      prepend-icon="checkmark-circle"
      @click="save"
      >{{ $t('nest.card.save') }}</ChButton
    >
    <ChButton
      v-if="enabled"
      icon="dismiss"
      :size="ButtonSize.SM"
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      @click="closeEdit"
    />
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { vResizeObserver } from '@vueuse/components'
  import {
    ButtonSize,
    ButtonVariant,
    ChButton,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'

  const model = defineModel({ required: true })

  const emits = defineEmits(['update:resource-title', 'cancel'])

  const titleWidth = ref(0)
  const enabled = ref(false)
  const editBtn = ref(false)

  const toggleEdit = () => {
    enabled.value = !enabled.value
  }

  const closeEdit = () => {
    enabled.value = false
    emits('cancel')
  }

  const showEdit = () => {
    editBtn.value = true
  }

  const hideEdit = () => {
    editBtn.value = false
  }

  const save = async () => {
    emits('update:resource-title')
    toggleEdit()
  }

  // const onResize = ({ width }: { width: number }) => {
  //     titleWidth.value = Math.round(width) + 10
  // }

  const onResize = (entries: any) => {
    const [entry] = entries
    const { width } = entry.contentRect
    titleWidth.value = width + 10
  }
</script>

<style scoped lang="scss">
  .editable-title {
    &__field {
      @apply min-w-3 focus:outline outline-woot-100 dark:outline-woot-500 transition-colors duration-200;
    }

    &__hidden-text {
      @apply -top-4 -left-4 max-h-0 opacity-0 absolute pointer-events-none;
    }
  }
</style>
