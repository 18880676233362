import HomeView from '@/views/HomeView.vue'
import AuthView from '@/views/auth/AuthView.vue'
import ResetView from '@/views/auth/reset/ResetView.vue'
import ResetCompleteView from '@/views/auth/reset/ResetCompleteView.vue'
import RegistrationView from '@/views/auth/RegistrationView.vue'
import NestView from '@/views/NestView.vue'
import TrainingView from '@/views/TrainingView.vue'
import ConfigurationView from '@/views/ConfigurationView.vue'
import HistoryView from '@/views/HistoryView.vue'
import ProfileView from '@/views/ProfileView.vue'
import TestView from '@/views/TestingView.vue'
import AdminView from '@/views/admin/AdminView.vue'
import OrganizationsView from '@/views/admin/OrganizationsView.vue'
import OrganizationView from '@/views/admin/OrganizationView.vue'
import UsersView from '@/views/admin/UsersView.vue'
import NotFound from '@/views/NotFound.vue'
import CrawlerView from '@/views/CrawlerView.vue'

export default [
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'home',
    path: '/',
    component: HomeView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'auth',
    path: '/auth',
    component: AuthView,
    meta: {
      layout: 'Auth',
    },
  },
  {
    name: 'registration',
    path: '/registration',
    component: RegistrationView,
    meta: {
      layout: 'Auth',
    },
  },
  {
    name: 'reset',
    path: '/reset',
    component: ResetView,
    meta: {
      layout: 'Auth',
    },
  },
  {
    name: 'reset-password',
    path: '/auth/reset-password',
    component: ResetCompleteView,
    meta: {
      layout: 'Auth',
    },
  },
  {
    name: 'profile',
    path: '/profile',
    component: ProfileView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'nest',
    path: '/:orgId/nest/:nestId',
    component: NestView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'crawler',
    path: '/:orgId/nest/:nestId/:scraperId',
    component: CrawlerView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'training',
    path: '/:orgId/nest/:nestId/training',
    component: TrainingView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'history',
    path: '/:orgId/nest/:nestId/history',
    component: HistoryView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'testing',
    path: '/:orgId/nest/:nestId/testing',
    component: TestView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'configuration',
    path: '/:orgId/nest/:nestId/configuration',
    component: ConfigurationView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'profile',
    path: '/profile',
    component: ProfileView,
    meta: {
      layout: 'Default',
    },
  },
  {
    name: 'admin',
    path: '/admin',
    component: AdminView,
    meta: {
      layout: 'Admin',
    },
  },
  {
    name: 'admin-organizations',
    path: '/admin/organizations',
    component: OrganizationsView,
    meta: {
      layout: 'Admin',
    },
  },
  {
    name: 'admin-organization',
    path: '/admin/organization/:id',
    component: OrganizationView,
    meta: {
      layout: 'Admin',
    },
  },
  {
    name: 'admin-users',
    path: '/admin/users',
    component: UsersView,
    meta: {
      layout: 'Admin',
    },
  },
]
