<template>
  <div class="flex flex-col items-center justify-center w-full py-8">
    <img
      v-if="imgName"
      class="w-64 dark:hidden"
      :src="imgPath()"
      alt="empty-state"
    />
    <img
      v-if="imgName"
      class="w-64 hidden dark:inline-block"
      :src="imgPathDark()"
      alt="empty-state"
    />
    <h2
      :class="'text-' + titleSize"
      class="text-gray-400 font-light text-center"
    >
      {{ title }}
    </h2>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    imgName: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleSize: {
      type: String,
      default: '3xl',
    },
  })

  const imgPath = () => {
    return new URL(`../../assets/${props.imgName}.png`, import.meta.url).href
  }

  const imgPathDark = () => {
    return new URL(`../../assets/${props.imgName}-dark.png`, import.meta.url)
      .href
  }
</script>

<style scoped lang="scss"></style>
