<template>
  <form class="form w-full" @submit.prevent="updateUser">
    <div
      class="w-full bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg shadow-lg p-4 flex flex-col space-y-4 items-start"
    >
      <h2 class="profile__title">{{ $t('profile.content.email.title') }}</h2>
      <ChInput
        v-model="user.email"
        :label="$t('profile.content.email.email')"
        class="w-full"
      />
      <!--      TODO: hidden for now-->
      <!--      <ai-input-->
      <!--        v-model="user.firstName"-->
      <!--        :label="$t('profile.content.email.firstName')"-->
      <!--        class="w-full"-->
      <!--      />-->
      <!--      <ai-input-->
      <!--        v-model="user.lastName"-->
      <!--        :label="$t('profile.content.email.lastName')"-->
      <!--        class="w-full"-->
      <!--      />-->
      <!--      <ai-input-->
      <!--        v-model="user.country"-->
      <!--        :label="$t('profile.content.email.country')"-->
      <!--        class="w-full"-->
      <!--      />-->
      <!--      <ai-input-->
      <!--        v-model="user.phoneNumber"-->
      <!--        :label="$t('profile.content.email.phoneNumber')"-->
      <!--        class="w-full"-->
      <!--      />-->
      <ChButton
        :disabled="!user.email || user.email.length < 5 || !changed"
        type="submit"
        >{{ $t('profile.content.email.update') }}</ChButton
      >
    </div>
  </form>
</template>

<script lang="ts" setup>
  import { computed, reactive, watch, ref } from 'vue'
  import { useUserStore } from '@/stores/user'
  import { ChButton, ChInput } from '@chatlyncom/chatlyn-ui-components'

  const userStore = useUserStore()

  const user = reactive({
    email: '',
    // firstName: '',
    // lastName: '',
    // country: '',
    // phoneNumber: '',
  })
  const copiedUser = reactive({
    email: '',
    // firstName: '',
    // lastName: '',
    // country: '',
    // phoneNumber: '',
  })
  const changed = ref(false)

  const userFetched = computed(() => userStore.getters.fetched())
  const me = computed(() => userStore.getters.user())

  watch(userFetched, () => {
    setUser()
  })

  const setUser = () => {
    user.email = me.value.email
    copiedUser.email = me.value.email
    // user.firstName = me.value.firstName
    // copiedUser.firstName = me.value.firstName
    // user.lastName = me.value.lastName
    // copiedUser.lastName = me.value.lastName
    // user.country = me.value.country
    // copiedUser.country = me.value.country
    // user.phoneNumber = me.value.phoneNumber
    // copiedUser.phoneNumber = me.value.phoneNumber
  }

  const updateUser = () => {
    userStore.actions.updateUser(me.value.userId, user)
  }

  setUser()

  watch(
    user,
    () => {
      changed.value = JSON.stringify(user) !== JSON.stringify(copiedUser)
    },
    { deep: true },
  )
</script>

<style lang="scss" scoped></style>
