import { createI18n } from 'vue-i18n'

import en from '@/infrastructure/locales/en.json'
import de from '@/infrastructure/locales/de.json'

const config = {
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  legacy: false,
  messages: {
    en,
    de,
  },
}

export default createI18n(config)
