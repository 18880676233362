export default (state: any) => {
  const setOrganizationsApiKeys = (payload: any) => {
    state.organizationsApiKeys = payload
  }

  const setApiKey = (payload: any) => {
    state.apiKey = payload
  }

  const setOrganizations = (payload: any) => {
    state.organizations = payload
  }

  const setOrganization = (payload: any) => {
    state.organization = payload
  }

  const setCurrent = (payload: any) => {
    state.current = payload
  }

  return {
    setOrganizationsApiKeys,
    setApiKey,
    setOrganizations,
    setOrganization,
    setCurrent,
  }
}
