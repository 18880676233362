import { defineStore } from 'pinia'
import state from '@/stores/global/state'
import mutations from '@/stores/global/mutations'
import getters from '@/stores/global/getters'

export const useGlobalStore = defineStore('global', () => {
  return {
    state,
    getters: getters(state),
    mutations: mutations(state),
  }
})
