<template>
  <div class="range-slider">
    <span class="range-slider__label">{{ label }}</span>
    <span class="range-slider__range range-slider__range-min">{{ min }}</span>
    <slider v-model="modelValue" :min="min" :max="max" class="w-full" />
    <span class="range-slider__range range-slider__range-max">{{ max }}</span>
  </div>
</template>

<script setup lang="ts">
  import Slider from '@vueform/slider'

  const modelValue = defineModel()

  defineProps({
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    min: {
      type: [String, Number],
      default: '0',
    },
    max: {
      type: [String, Number],
      default: '10',
    },
    showRangeLabels: {
      type: Boolean,
      default: true,
    },
  })
</script>

<style scoped lang="scss">
  .range-slider {
    @apply relative py-4;

    &__label {
      @apply text-sm text-gray-700 dark:text-gray-200 mb-2 capitalize;
    }

    &__range {
      @apply absolute text-sm text-gray-400 -bottom-1;

      &-min {
        @apply left-0;
      }

      &-max {
        @apply right-0;
      }
    }
  }
</style>
