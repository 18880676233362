export default (state: any) => {
  const setDialog = (payload: any) => {
    state.dialog = payload
  }

  const setDialogs = (payload: any) => {
    state.dialogs = payload
  }

  const setTrainingDialog = (payload: any) => {
    state.trainingDialog = payload
  }

  const setHistoryDialogs = (payload: any) => {
    state.historyDialogs = payload
  }

  const setInteractions = (payload: any) => {
    state.interactions = payload
  }

  const setInteraction = (payload: any) => {
    state.interaction = payload
  }

  return {
    setDialog,
    setDialogs,
    setTrainingDialog,
    setHistoryDialogs,
    setInteractions,
    setInteraction,
  }
}
