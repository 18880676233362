<template>
  <ul class="skeleton" :class="[axisClass, spaceClass, wrapperClass]">
    <li
      class="skeleton__item"
      :class="[width, height, itemClass, rounding]"
      v-for="item in quantity"
      :key="item"
    ></li>
  </ul>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    quantity: {
      type: Number,
      default: 1,
    },
    axis: {
      type: String,
      default: 'y',
    },
    width: {
      type: String,
      default: 'w-full',
    },
    height: {
      type: String,
      default: 'h-4',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    itemClass: {
      type: String,
      default: '',
    },
    space: {
      type: String,
      default: '2',
    },
    rounding: {
      type: String,
      default: 'rounded',
    },
  })

  const axisClass = computed(() =>
    props.axis === 'x' ? 'skeleton__x' : 'skeleton__y',
  )

  const spaceClass = computed(() => `space-${props.axis}-${props.space}`)
</script>

<style scoped lang="scss">
  .skeleton {
    @apply flex w-full;

    &__item {
      @apply opacity-10 bg-gradient-to-r from-gray-400 to-gray-500;

      animation: shimmer 10s infinite linear;
    }

    &__x {
      @apply flex-row;
    }

    &__y {
      @apply flex-col;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
</style>
