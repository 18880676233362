import { reactive } from 'vue'

export default reactive<any>({
  intent: null,
  intents: [],
  saved: null,
  reseted: null,
  flags: {
    loading: true,
  },
})
