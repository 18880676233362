export default (state: any) => {
  const nestDialog = () => {
    return state.nestDialog
  }

  const deleteNestDialog = () => {
    return state.deleteNestDialog
  }

  const resourceDialog = () => {
    return state.resourceDialog
  }

  const resourceEditorDialog = () => {
    return state.resourceEditorDialog
  }

  const improveAnswerDialog = () => {
    return state.improveAnswerDialog
  }

  const improveHistoryAnswerDialog = () => {
    return state.improveHistoryAnswerDialog
  }

  const improveTestingAnswerDialog = () => {
    return state.improveTestingAnswerDialog
  }

  const deleteIntentDialog = () => {
    return state.deleteIntentDialog
  }

  const webCrawlerDialog = () => {
    return state.webCrawlerDialog
  }

  const loader = () => {
    return state.loader
  }

  return {
    nestDialog,
    deleteNestDialog,
    resourceDialog,
    resourceEditorDialog,
    improveAnswerDialog,
    improveHistoryAnswerDialog,
    improveTestingAnswerDialog,
    deleteIntentDialog,
    webCrawlerDialog,
    loader,
  }
}
