import axios from 'axios'
import type { CreateCrawlerBody, ExtractUrlsBody } from '@/types/interfaces'

export default {
  fetchFeaturesFlags: (organizationId: string) => {
    return axios.get(`feature-access/organizations/${organizationId}`)
  },

  extractUrls: (
    organizationId: string,
    nestId: string,
    data: ExtractUrlsBody,
  ) => {
    return axios.post(
      `organizations/${organizationId}/nests/${nestId}/extract-urls`,
      data,
    )
  },

  fetchWebScrapers: (organizationId: string, nestId: string) => {
    return axios.get(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers`,
    )
  },

  fetchScraper: (organizationId: string, nestId: string, scraperId: string) => {
    return axios.get(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}`,
    )
  },

  fetchScraperPages: (
    organizationId: string,
    nestId: string,
    scraperId: string,
    page: number,
    search?: string,
    orderBy?: string,
    orderDirection?: string,
  ) => {
    const params = new URLSearchParams({
      page: page.toString(),
      limit: '50',
    })
    if (search !== undefined) {
      params.append('urlSearch', search)
    }

    if (orderBy && orderDirection) {
      params.append('orderBy', orderBy)
      params.append('orderDirection', orderDirection)
    }

    return axios.get(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}/webpages/?${params}`,
    )
  },

  addPages: (
    organizationId: string,
    nestId: string,
    scraperId: string,
    data: string[],
  ) => {
    return axios.post(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}/webpages`,
      { urls: data },
    )
  },

  deleteScraperPages: (
    organizationId: string,
    nestId: string,
    scraperId: string,
    ids: number[],
  ) => {
    return axios.delete(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}/webpages`,
      { data: { webpageIds: ids } },
    )
  },

  createCrawler: (
    organizationId: string,
    nestId: string,
    data: CreateCrawlerBody,
  ) => {
    return axios.post(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers`,
      data,
    )
  },

  runScraper: (
    organizationId: string,
    nestId: string,
    scraperId: string,
    urls: number[],
  ) => {
    return axios.post(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}`,
      { webpageIds: urls },
    )
  },

  updateWebScraper: (
    organizationId: string,
    nestId: string,
    scraperId: string,
    data: any,
  ) => {
    return axios.patch(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}`,
      data,
    )
  },

  deleteScraper: (
    organizationId: string,
    nestId: string,
    scraperId: string,
  ) => {
    return axios.delete(
      `organizations/${organizationId}/nests/${nestId}/web-scrapers/${scraperId}`,
    )
  },
}
