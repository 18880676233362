import type { CrawlingState, Scraper } from '@/types/interfaces'

export default (state: CrawlingState) => {
  const setFeatureFlags = (payload: any) => {
    state.featureFlags = payload
  }

  const setExtractedUrls = (payload: string[]) => {
    state.extractedUrls = payload
  }

  const setUrlsTypes = (payload: any) => {
    state.urlsTypes = payload
  }

  const setSelectedUrls = (payload: any[]) => {
    state.selectedUrls = payload
  }

  const setWebScrapers = (payload: any) => {
    state.webScrapers = payload
  }

  const clearWebScrapers = () => {
    state.webScrapers = {
      items: [],
      count: 0,
    }
  }

  const toggleExtractingUrlsFlag = () => {
    state.flags.extractingUrls = !state.flags.extractingUrls
  }

  const setScraper = (payload: Scraper) => {
    state.scraper = payload
  }

  const setWebpages = (payload: any) => {
    state.webpages = payload
  }

  return {
    setFeatureFlags,
    setExtractedUrls,
    setUrlsTypes,
    setSelectedUrls,
    setWebScrapers,
    clearWebScrapers,
    toggleExtractingUrlsFlag,
    setScraper,
    setWebpages,
  }
}
