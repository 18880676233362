<template>
  <div class="schedule-container">
    <h3 class="schedule-container__title">
      {{ $t('crawler-dialog.second-step.executions.manual.title') }}
    </h3>
    <p class="schedule-container__description">
      {{ $t('crawler-dialog.second-step.executions.manual.description') }}
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .schedule-container {
    @apply bg-gray-100 dark:bg-gray-800 text-gray-500 w-full rounded-lg text-center p-4;

    &__title {
      @apply mb-2 font-semibold;
    }

    &__description {
      @apply text-sm;
    }
  }
</style>
