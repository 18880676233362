import { isArray } from 'lodash'
import { toast } from 'vue3-toastify'

export const showErrors = (message: any) => {
  if (isArray(message)) {
    message.forEach((message: string) => {
      toast.error(message)
    })
  } else {
    toast.error(message)
  }
}
