<template>
  <div class="training-sidebar">
    <h2 class="training-sidebar__title">
      {{ $t('training.content.stored') }}
    </h2>
    <div class="training-sidebar__container">
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <SkeletonLoader
          v-if="intentFlagLoading"
          :quantity="3"
          height="h-9"
          wrapper-class="p-2"
          space="2"
        />
      </transition>
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <ul
          v-if="intents.length > 0 && !intentFlagLoading"
          class="training-sidebar__list"
        >
          <TrainingSidebarItem
            v-for="intent in intents"
            :key="intent.intentId"
            :intent="intent"
          />
        </ul>
      </transition>
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <EmptyState
          v-if="intents.length === 0 && !intentFlagLoading"
          class="training-sidebar__empty"
          img-name="EmptyInbox"
          title-size="lg"
          :title="$t('training.content.empty')"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import { useIntentStore } from '@/stores/intents'
  import { useRouteParams } from '@/composables/route-params'
  import type { Intent } from '@/types/interfaces'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import TrainingSidebarItem from '@/components/app/training/TrainingSidebarItem.vue'
  import SkeletonLoader from '@/components/ui/skeleton/SkeletonLoader.vue'

  const intentStore = useIntentStore()

  const { orgId, nestId } = useRouteParams()

  const intents = computed<Intent[]>(() => intentStore.getters.intents())
  const intentFlagLoading = computed(() => intentStore.getters.flagLoading())

  const fetchIntents = async (load: boolean) => {
    await intentStore.actions.fetchIntents(orgId.value, nestId.value, load)
  }

  onMounted(() => {
    fetchIntents(true)
  })
</script>

<style scoped lang="scss">
  .training-sidebar {
    @apply flex flex-col w-1/4 min-w-64 h-full;

    &__title {
      @apply uppercase tracking-widest text-gray-400 dark:text-gray-500 text-sm my-4 px-4;
    }

    &__container {
      @apply w-full h-full bg-white dark:bg-gray-800 border dark:border-gray-800 rounded overflow-y-auto p-2 flex flex-col gap-1 text-sm;
    }

    &__list {
      animation-delay: 0.5s;
    }

    &__item {
      @apply w-full flex items-center justify-between gap-2 cursor-pointer;
    }

    &__question {
      @apply truncate flex-1 block p-3;
    }

    &__empty {
      animation-delay: 0.5s;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
