import nests from '@/api/nests/nests'
import { toast } from 'vue3-toastify'
import { useChatStore } from '@/stores/chat'

export default (mutations: any) => {
  const chatStore = useChatStore()
  const fetchIntents = async (
    organizationId: string,
    nestId: string,
    load: boolean,
  ) => {
    try {
      if (load) {
        mutations.setFlags('loading', true)
      }
      const { data } = await nests.fetchIntents(organizationId, nestId)
      mutations.setIntents(data)
      if (load) {
        mutations.setFlags('loading', false)
      }
    } catch (error) {
      toast.error((error as Error).message)
      if (load) {
        mutations.setFlags('loading', false)
      }
    }
  }

  const fetchIntent = async (
    organizationId: string,
    nestId: string,
    intentId: number,
  ) => {
    try {
      // mutations.setFlags('loading', true)
      const { data } = await nests.fetchIntent(organizationId, nestId, intentId)
      mutations.setIntent(data)
      mutations.setResetedIntent(null)
      // mutations.setFlags('loading', false)
    } catch (error) {
      toast.error((error as any).response.data.message)
      // mutations.setFlags('loading', false)
    }
  }

  const createIntent = async (
    organizationId: string,
    nestId: string,
    intent: any,
  ) => {
    try {
      // mutations.setFlags('loading', true)
      const { data } = await nests.createIntent(organizationId, nestId, intent)
      mutations.setIntent(data)
      mutations.setResetedIntent(null)
      chatStore.mutations.setAnswers([])
      chatStore.mutations.setQuestion('')
      chatStore.mutations.setSelectedAnswer(null)
      chatStore.mutations.setItem(null)
      // mutations.setFlags('loading', false)
      toast.success('Successfully created')
    } catch (error) {
      // mutations.setFlags('loading', false)
      toast.error((error as Error).message)
    }
  }

  const deleteIntent = async (
    organizationId: string,
    nestId: string,
    intentId: number,
  ) => {
    try {
      await nests.deleteIntent(organizationId, nestId, intentId)
      mutations.setIntent(null)
      toast.success('Successfully deleted')
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  return {
    fetchIntents,
    fetchIntent,
    createIntent,
    deleteIntent,
  }
}
