<template>
  <div class="custom-schedule">
    <ChInput
      v-model="model"
      class="custom-schedule__input"
      :label="$t('crawler-dialog.second-step.executions.custom.label')"
    />
    <p class="custom-schedule__description">
      {{ $t('crawler-dialog.second-step.executions.custom.text') }}
      <a class="custom-schedule__link" href="https://crontab.guru/"
        >crontab guru</a
      >.
    </p>
  </div>
</template>

<script setup lang="ts">
  import { ChInput } from '@chatlyncom/chatlyn-ui-components'

  const model = defineModel({ default: '* * * * *' })
</script>

<style scoped lang="scss">
  .custom-schedule {
    @apply flex flex-col w-full;

    &__input {
      @apply mb-4;
    }

    &__description {
      @apply p-4 rounded-lg bg-yellow-50 text-yellow-700 text-sm dark:bg-yellow-700 dark:text-yellow-100;
    }

    &__link {
      @apply underline;
    }
  }
</style>
