<template>
  <main class="main">
    <div class="container flex flex-col">
      <InfoBox
        :title="$t('testing.info-box.title')"
        :description="$t('testing.info-box.description')"
        img-name="feedback-message"
        info-box-name="testingInfoBox"
        route-name="testing"
        show-close-icon
      />
      <TestingContainer />
    </div>
  </main>
</template>

<script lang="ts" setup>
  import { useRoute } from 'vue-router'
  import TestingContainer from '@/components/app/testing/TestingContainer.vue'
  import { useNestStore } from '@/stores/nests'
  import { useDialogsStore } from '@/stores/dialogs'
  import { computed } from 'vue'
  import { useGlobalStore } from '@/stores/global'
  import InfoBox from '@/components/app/global/InfoBox.vue'

  const route = useRoute()
  const globalStore = useGlobalStore()
  const nestStore = useNestStore()
  const dialogStore = useDialogsStore()

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  nestStore.actions.fetchNest(orgId.value, nestId.value)

  const secret = computed(() => nestStore.getters.secret())
  const trainingDialog = computed(() => dialogStore.getters.trainingDialog())

  const getData = async () => {
    await globalStore.mutations.showLoader()
    await nestStore.actions.fetchSecret(orgId.value, nestId.value)
    await dialogStore.actions.fetchTrainingDialog(orgId.value, nestId.value)
    if (!trainingDialog.value) {
      await dialogStore.actions.createDialog(orgId.value, nestId.value, {
        guest: {
          identifier: localStorage.getItem('email'),
        },
        secretKey: secret.value,
        training: true,
      })
    }
    await globalStore.mutations.hideLoader()
  }

  getData()
</script>

<style lang="scss" scoped></style>
