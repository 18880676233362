<template>
  <div class="radio">
    <input
      v-model="modelValue"
      :id="id"
      name="radio"
      type="radio"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      :value="value"
      @change="change"
    />
    <label v-if="!label" :for="id" class="radio-label">
      <slot />
    </label>
    <label v-else :for="id" class="radio-label">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
  const modelValue = defineModel()
  defineProps({
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: '',
    },
  })

  const emits = defineEmits(['change'])

  const change = (e: Event) => {
    emits('change', e)
  }
</script>

<style scoped lang="scss">
  .radio {
    @apply m-2 relative;

    input[type='radio'] {
      @apply absolute opacity-0;

      + .radio-label {
        @apply cursor-pointer flex items-center;

        &:before {
          content: '';
          @apply bg-white rounded-full inline-block w-4 h-4 min-w-4 min-h-4 relative mr-4 align-top cursor-pointer text-center transition-all duration-200 border border-gray-300;
        }
      }

      &:checked {
        + .radio-label {
          &:before {
            @apply bg-violet-500;
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }

      &:focus {
        + .radio-label {
          &:before {
            @apply border-violet-500 outline outline-violet-100 dark:outline-violet-800;
          }
        }
      }

      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;

            @apply bg-gray-300 border-gray-300;
          }
        }
      }

      + .radio-label {
        &:empty {
          &:before {
            @apply mr-0;
          }
        }
      }
    }

    &:focus-within input[type='radio']:checked + .radio-label:before {
      @apply border-violet-500 outline outline-violet-100 dark:outline-violet-800;
    }
  }
</style>
