<template>
  <form @submit.prevent="reset" class="auth-form">
    <div class="flex mb-12 relative">
      <img
        class="mx-auto"
        :width="40"
        :height="40"
        :src="imgLoader('logo', 'svg')"
        alt="logo"
      />
    </div>
    <ChInput v-model="email" :label="$t('auth.email')" class="mb-4" required />
    <ChButton :size="ButtonSize.LG" :disabled="!checkEmail" type="submit">
      {{ $t('auth.request-password') }}
    </ChButton>
    <ChButton
      :variant="ButtonVariant.TEXT"
      :to="{
        name: 'auth',
      }"
    >
      {{ $t('auth.signIn') }}
    </ChButton>
  </form>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import imgLoader from '@/utils/img-loader'
  import {
    ButtonSize,
    ButtonVariant,
    ChButton,
    ChInput,
  } from '@chatlyncom/chatlyn-ui-components'

  const authStore = useAuthStore()

  const emits = defineEmits(['sent'])

  const email = ref<string>('')

  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const checkEmail = computed(() => {
    return emailRegex.test(email.value)
  })

  const reset = () => {
    const encodedEmail = encodeURIComponent(email.value)
    authStore.actions.passwordReset(encodedEmail)
    emits('sent')
  }
</script>

<style lang="scss" scoped>
  .auth-form {
    @apply flex flex-col content-stretch justify-center w-full bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6;
  }
</style>
