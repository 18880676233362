export const getUserFromJWT = (token: string): any => {
  const payload = token.split('.')[1]
  const decoded = atob(payload)
  return JSON.parse(decoded)
}

export const getUserId = (accessToken: string): string => {
  const payload = accessToken.split('.')[1]
  const decoded = atob(payload)
  return JSON.parse(decoded).userId.toString()
}

export const parseToken = (token: string) => {
  const payload = token.split('.')[1]
  const decoded = atob(payload)
  return JSON.parse(decoded)
}

export const getInitials = (name: string) => {
  const words = name.split(' ')

  const isFirstWordSingle = words.length === 1
  return isFirstWordSingle
    ? name.slice(0, 1).toUpperCase() + name.slice(1, 2).toLowerCase()
    : `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`
}
