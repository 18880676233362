export default (state: any) => {
  const trainingDialog = () => {
    return state.trainingDialog
  }

  const historyDialogs = () => {
    return state.historyDialogs.items
  }

  const interactions = () => {
    return state.interactions.items
  }

  const interactionsCount = () => {
    return state.interactions.count
  }

  const dialogsCount = () => {
    return state.historyDialogs.count
  }

  const interaction = () => {
    return state.interaction
  }

  return {
    trainingDialog,
    historyDialogs,
    dialogsCount,
    interactions,
    interactionsCount,
    interaction,
  }
}
