import axios from 'axios'

export default {
  generateTokens: (data: any) => {
    return axios.post('/authorization/generate-tokens', data)
  },

  refreshTokens: (data: any) => {
    return axios.post('/authorization/refresh-tokens', data)
  },

  nullifyTokens: (data: any) => {
    return axios.post('/authorization/nullify-tokens', data)
  },

  passwordReset: (email: any) => {
    return axios.get(`/password-reset/initiate?email=${email}`)
  },

  passwordResetComplete: (data: any) => {
    return axios.post('/password-reset/complete', data)
  },

  resendVerificationEmail: (email: string) => {
    return axios.get('/registration/resend-verification-email?email=' + email)
  },

  registration: (data: any) => {
    return axios.post('/password-reset/initiate', data)
  },

  createUser: (data: any) => {
    return axios.post('/users', data)
  },
}
