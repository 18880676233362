<template>
  <div
    :class="[
      { 'no-overlay': !overlay, animate__fadeOut: show },
      positionClasses,
    ]"
    class="overlay animate__animated animate__faster animate__fadeIn"
    @click.self="closeOutside"
  >
    <div
      :class="['dialog-' + size]"
      class="dialog animate__animated animate__faster animate__zoomIn"
    >
      <slot v-if="!title" name="header" />
      <div v-else class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <ChButton
          icon="dismiss"
          :variant="ButtonVariant.CLEAR"
          :color="ColorScheme.ALERT"
          @click="close"
        />
      </div>
      <div class="dialog__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, onUnmounted } from 'vue'
  import {
    ButtonVariant,
    ChButton,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'center',
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
  })

  const emits = defineEmits(['close'])

  const positionClasses = computed(() => {
    switch (props.position) {
      case 'top':
        return 'justify-center items-start'
      case 'bottom':
        return 'justify-center items-end'
      case 'left':
        return 'justify-start items-center'
      case 'right':
        return 'justify-end items-center'
      default:
        return 'justify-center items-center'
    }
  })

  onMounted(() => {
    window.addEventListener('keydown', onEsc)
  })

  onUnmounted(() => {
    window.removeEventListener('keydown', onEsc)
  })

  const onEsc = (event: any) => {
    if (event.key === 'Escape') {
      close()
    }
  }

  const close = () => {
    emits('close')
  }

  const closeOutside = () => {
    if (props.clickOutside) {
      emits('close')
    }
  }
</script>
<style lang="scss" scoped>
  .overlay {
    @apply z-40 fixed inset-0 w-full h-screen flex bg-black bg-opacity-30 dark:bg-opacity-50;

    &.no-overlay {
      @apply bg-transparent;
    }
  }
</style>

<style lang="scss">
  .dialog {
    @apply flex flex-col bg-white dark:bg-gray-800 rounded-md overflow-clip shadow-lg w-full mx-4 max-w-96;

    &__header {
      @apply flex justify-between pt-2 px-2 gap-3 mb-2;
    }

    &__title {
      @apply text-lg font-semibold dark:text-white ms-2 mt-1.5;
    }

    &.dialog-sm {
      @apply max-w-96;
    }

    &.dialog-md {
      @apply max-w-[512px];
    }

    &.dialog-lg {
      @apply max-w-[742px];
    }

    &.dialog-xl {
      @apply max-w-[1024px];
    }
  }

  .dialog-body {
    @apply px-4 py-2;
  }
  .dialog-footer {
    @apply p-4 border-t dark:border-gray-700;
  }
</style>
