<template>
  <v-main class="admin-main">
    <v-container class="page-wrapper d-flex flex-column">
      <v-tabs
        v-model="currentTab"
        color="primary"
        align-tabs="start"
        class="mb-4"
      >
        <v-tab class="text-uppercase" value="information">Information</v-tab>
        <v-tab class="text-uppercase" value="users">Users</v-tab>
      </v-tabs>
      <v-window v-model="currentTab">
        <v-window-item value="information">
          <v-row>
            <v-col cols="6">
              <organization-card title="Owner" :data="owner" />
            </v-col>
            <v-col cols="6">
              <organization-card title="Limitations" :data="limitations" />
            </v-col>
            <v-col cols="6">
              <organization-card title="Account" :data="account" />
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item value="users">
          <users-table />
        </v-window-item>
      </v-window>
    </v-container>
  </v-main>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import OrganizationCard from '@/components/admin/organizations/OrganizationCard.vue'
  import UsersTable from '@/components/admin/organizations/UsersTable.vue'
  import { useUserStore } from '@/stores/user'
  import { useMemberStore } from '@/stores/members'
  import { useRoute } from 'vue-router'

  const route = useRoute()
  const userStore = useUserStore()
  const memberStore = useMemberStore()

  const orgId = computed(() => route.params.id as string)

  const currentTab = ref(null)

  const owner = {
    title: 'John Doe',
    firstName: 'asdasdas',
    lastName: 'asdasdasd',
    email: 'asdasdasd',
    phone: 'asdasdasd',
    address: 'asdasdasd',
    country: 'asdasdasd',
  }

  const limitations = {
    nests: 1,
    sources: 1,
    users: 1,
    requests: 1,
    accountTier: 3,
  }

  const account = {
    name: 'John Doe',
    fieldOfActivity: 'asdasdas',
  }

  userStore.actions.getUsers()
  memberStore.actions.fetchMembers(orgId.value)
</script>

<style lang="scss" scoped></style>
