import axios from 'axios'

export default {
  fetchMembers: (organizationId: string) => {
    return axios.get(`organizations/${organizationId}/members`)
  },
  inviteMember: (organizationId: string, data: any) => {
    return axios.post(`organizations/${organizationId}/members/invite`, data)
  },
  removeMember: (organizationId: string, memberId: string) => {
    return axios.patch(`organizations/${organizationId}/members/${memberId}`)
  },
  updateMemberRole: (organizationId: string, memberId: string, data: any) => {
    return axios.patch(
      `organizations/${organizationId}/members/${memberId}/role`,
      data,
    )
  },
}
