<template>
  <div class="training-own-answer">
    <span v-element-size="onResize" class="training-own-answer__hidden">{{
      modelValue
    }}</span>
    <ai-textarea
      v-model="modelValue"
      size="lg"
      :style="{ width: titleWidth + 'px' }"
      class="training-own-answer__field"
      placeholder="Type your own answer ..."
    />
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { vElementSize } from '@vueuse/components'
  import AiTextarea from '@/components/ui/AiTextarea.vue'

  const modelValue = defineModel({ required: true, type: String })

  const titleWidth = ref(0)

  const onResize = ({ width }: { width: number }) => {
    titleWidth.value = width + 24
  }
</script>

<style scoped lang="scss">
  .training-own-answer {
    @apply w-full relative;

    &__hidden {
      @apply -top-4 -left-4 max-h-0 opacity-0 absolute pointer-events-none text-sm;
    }

    &__field {
      min-width: 230px;
      max-width: calc(100% - 8rem);
    }
  }
</style>
