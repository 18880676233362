<template>
  <div class="field">
    <label v-if="label" class="field__label">{{ label }}</label>
    <div
      class="field__wrapper textarea form-control"
      :class="['field__wrapper-' + size]"
    >
      <ai-icon
        v-if="prependIcon && $slots.prependIcon"
        :icon="prependIcon"
        :classs="{ 'cursor-pointer': disabled }"
        class="field__icon field__icon-prepend"
        @click="prependIconClick"
      />
      <slot name="prependIcon" />
      <textarea
        v-model="input"
        ref="textarea"
        class="field__input"
        :class="{ 'resize-none': noResize, 'large-height': largeHeight }"
        :required="required"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        @change="changeEvent"
        @keyup]="change"
      />
      <ai-icon
        v-if="appendIcon && $slots.appendIcon"
        :icon="appendIcon"
        :classs="{ 'cursor-pointer': disabled }"
        class="field__icon field__icon-append"
        @click="appendIconClick"
      />
      <slot name="appendIcon" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useTextareaAutosize } from '@vueuse/core'
  import { onMounted, watch } from 'vue'

  const props = defineProps({
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    largeHeight: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
  })

  const emits = defineEmits([
    'append-icon-click',
    'prepend-icon-click',
    'change',
    'update:modelValue',
  ])

  const { textarea, input } = useTextareaAutosize()

  watch(input, () => {
    emits('update:modelValue', input.value)
  })

  watch(
    () => props.modelValue,
    (newVal) => {
      input.value = newVal
    },
  )

  const appendIconClick = () => {
    if (!props.disabled) emits('append-icon-click')
  }

  const prependIconClick = () => {
    if (!props.disabled) emits('prepend-icon-click')
  }

  const change = () => {
    emits('update:modelValue', input.value)
  }

  const changeEvent = (e: Event) => {
    emits('change', e)
  }

  onMounted(() => {
    input.value = props.modelValue
  })
</script>

<style scoped lang="scss">
  .field {
    @apply flex flex-col;

    &__label {
      @apply text-gray-700 dark:text-gray-300 text-sm mb-1;
    }

    &__wrapper {
      @apply flex items-center;

      &:focus-within {
        @apply text-gray-900 dark:text-white;
      }
    }

    &__input {
      @apply flex-1 w-full text-sm max-h-64 bg-transparent border-none outline-none text-gray-900 dark:text-gray-100 p-2.5 leading-6;

      &.large-height {
        @apply min-h-40;
      }

      &:disabled {
        @apply cursor-not-allowed bg-transparent;
      }
    }

    &__icon {
      &-prepend {
        @apply mr-2;
      }

      &-append {
        @apply ml-2;
      }
    }
  }
</style>
