export const checkFrame = () => {}

export const setFrame = (value: boolean) => {
  localStorage.setItem('frame', value.toString())
}

export const getFrame = () => {
  return localStorage.getItem('frame')
}

export const getPostMessage = () => {}
