<template>
  <div>
    <h1>404 Not Found</h1>
    <p>The page you were looking for could not be found.</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
