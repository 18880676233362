export default (state: any) => {
  const intent = () => {
    return state.intent
  }

  const intents = () => {
    return state.intents
  }

  const saved = () => {
    return state.stored
  }

  const reseted = () => {
    return state.reseted
  }

  const flags = () => {
    return state.flags
  }

  const flagLoading = () => {
    return state.flags.loading
  }

  return {
    intent,
    intents,
    saved,
    reseted,
    flags,
    flagLoading,
  }
}
