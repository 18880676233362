export default (state: any) => {
  const setNests = (payload: any) => {
    state.nests.count += payload.count
    state.nests.items = payload.items
  }

  const setNest = (payload: any) => {
    state.nest = payload
  }

  const setCreatedNest = (payload: any) => {
    state.created = payload
  }

  const setNestOptions = (payload: any) => {
    state.options = payload
  }

  const setSecret = (payload: any) => {
    state.secret = payload
  }

  const setFlags = (flagName: string, value: boolean) => {
    state.flags[flagName] = value
  }

  return {
    setNests,
    setNest,
    setCreatedNest,
    setNestOptions,
    setSecret,
    setFlags,
  }
}
