import { type Ref, ref } from 'vue'

export const useCron = (
  cronString: Ref<string>,
  hours: Ref<string>,
  minutes: Ref<string>,
  days: Ref<string[]>,
) => {
  const changedCronString = ref(cronString)

  const formatSequence = (
    acc: string,
    val: number,
    index: number,
    array: number[],
  ) => {
    if (index === 0 || array[index - 1] !== val - 1) {
      return acc + ',' + val.toString()
    } else if (index < array.length - 1 && array[index + 1] !== val + 1) {
      return acc + '-' + val.toString()
    } else if (index === array.length - 1) {
      return acc + '-' + val.toString()
    }
    return acc
  }

  const condense = (array: number[]) => {
    return array.reduce(formatSequence, '').substring(1)
  }

  const calcHours = () => {
    const cronArray = changedCronString.value.split(' ')
    cronArray[1] = hours.value

    changedCronString.value = cronArray.join(' ')
  }

  const calcMinutes = () => {
    const cronArray = changedCronString.value.split(' ')
    cronArray[0] = minutes.value

    changedCronString.value = cronArray.join(' ')
  }

  const calcDays = () => {
    const cronArray = changedCronString.value.split(' ')

    if (days.value.length === 7 || days.value.length === 0) {
      cronArray[4] = '*'

      changedCronString.value = cronArray.join(' ')
    } else {
      const sortedDays = [...days.value.map(Number)].sort((a, b) => a - b)
      cronArray[4] = condense(sortedDays)

      changedCronString.value = cronArray.join(' ')
    }
  }

  const calcAll = () => {
    changedCronString.value = cronString.value

    calcHours()
    calcMinutes()
    calcDays()

    return changedCronString.value
  }

  return calcAll()
}
