import { reactive } from 'vue'
import type { CrawlingState } from '@/types/interfaces'

export default reactive<CrawlingState>({
  extractedUrls: [],
  urlsTypes: {
    duplicateURLs: [],
    invalidURLs: [],
    webpages: [],
  },
  selectedUrls: [],
  webScrapers: {
    items: [],
    count: 0,
  },
  flags: {
    extractingUrls: false,
  },
  scraper: null,
  webpages: {
    items: [],
    count: 0,
  },
  featureFlags: [],
})
