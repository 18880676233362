export default (state: any) => {
  const setResources = (payload: any) => {
    state.resources = payload
  }

  const clearResources = () => {
    state.resources = {
      count: 0,
      items: [],
    }
  }

  const setResource = (payload: any) => {
    state.resource = payload
  }

  const clearResource = () => {
    state.resource = null
  }

  return {
    setResources,
    clearResources,
    setResource,
    clearResource,
  }
}
