import { reactive } from 'vue'

export default reactive<any>({
  nests: {
    count: 0,
    items: [],
  },
  nest: null,
  options: null,
  created: null,
  secret: null,
  flags: {
    loading: true,
  },
})
