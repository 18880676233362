import type { App } from 'vue'
import Vue3Toastify from 'vue3-toastify'
import type { ToastContainerOptions } from 'vue3-toastify'
import AiIcon from '@/components/app/global/AiIcon.vue'
import InfoBox from '@/components/app/global/InfoBox.vue'

export default (app: App): void => {
  app.component('AiIcon', AiIcon)
  app.component('InfoBox', InfoBox)
  app.use(Vue3Toastify, {
    theme: 'auto',
    autoClose: 5000,
    toastClassName: 'bg-borderColor',
  } as ToastContainerOptions)
}
