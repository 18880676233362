import { reactive } from 'vue'

export default reactive<any>({
  dialog: null,
  historyDialogs: {
    count: 0,
    items: [],
  },
  dialogs: {
    count: 0,
    items: [],
  },
  interactions: {
    count: 0,
    items: [],
  },
  interaction: null,
  trainingDialog: null,
})
