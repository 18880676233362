<template>
  <div
    class="rounded-lg flex items-center border dark:border-gray-700 bg-white dark:bg-gray-800 w-full px-8 py-6"
  >
    <div class="mr-4 dark:text-white">
      <ChIcon v-if="isPDF" icon="pdf" :size="32" />
      <ChIcon v-if="isText" icon="file" :size="32" />
      <ChIcon v-if="isURL" icon="globe" :size="32" />
    </div>
    <div class="flex-1">
      <div class="flex flex-col items-start">
        <ResourceCardTitle
          v-model="title"
          @update:resource-title="saveTitle"
          @cancel="closeEdit"
        />
        <div class="flex items-center text-gray-400 text-xs">
          <p>
            {{ date }}
          </p>
          <div
            v-if="resource.type !== 'WEBPAGE'"
            class="flex items-center status ml-4"
          >
            <div class="w-2 h-2 rounded-full mr-1" :class="statusColor"></div>
            <span class="status__text">{{ statusText }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex gap-1 items-center">
        <ChButton
          v-if="isPDF"
          :color="ColorScheme.SECONDARY"
          :variant="ButtonVariant.CLEAR"
          @click="getFilePath"
          >{{ $t('nest.card.view-file') }}</ChButton
        >
        <ChButton
          v-if="isText"
          :color="ColorScheme.SECONDARY"
          :variant="ButtonVariant.CLEAR"
          @click="toggleResourceEditorDialog"
          >{{ $t('nest.card.editor') }}</ChButton
        >
        <ChButton
          v-if="isURL"
          :color="ColorScheme.SECONDARY"
          :variant="ButtonVariant.CLEAR"
          :to="{
            name: 'crawler',
            params: {
              orgId: orgId,
              nestId: nestId,
              scraperId: resource.webScraperId,
            },
            query: { page: '1' },
          }"
          >{{ $t('nest.card.crawler') }}</ChButton
        >
        <ChButton
          icon="delete"
          :color="ColorScheme.ALERT"
          :variant="ButtonVariant.SMOOTH"
          @click="toggleDeleteDialog"
          >{{ $t('nest.card.delete') }}</ChButton
        >
      </div>
    </div>
  </div>
  <AiDialog
    v-if="showDeleteDialog"
    :title="$t('nest.card.delete-dialog.title')"
    size="sm"
    @close="toggleDeleteDialog"
  >
    <div class="dialog-body text-sm">
      {{ $t('nest.card.delete-dialog.description-resource') }}
    </div>
    <div class="flex flex-wrap items-center justify-end gap-1 dialog-body">
      <ChButton
        :variant="ButtonVariant.CLEAR"
        :color="ColorScheme.SECONDARY"
        @click="toggleDeleteDialog"
        >{{ $t('nest.card.delete-dialog.cancel') }}</ChButton
      >
      <ChButton
        :variant="ButtonVariant.SMOOTH"
        :color="ColorScheme.ALERT"
        @click="deleteResource"
        >{{ $t('nest.card.delete-dialog.confirm') }}</ChButton
      >
    </div>
  </AiDialog>
</template>

<script setup lang="ts">
  import moment from 'moment/moment'
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useResourceStore } from '@/stores/resources'
  import { useGlobalStore } from '@/stores/global'
  import ResourceCardTitle from '@/components/app/resources/ResourceCardTitle.vue'
  import AiDialog from '@/components/ui/AiDialog.vue'
  import { useRouteParams } from '@/composables/route-params'
  import { useCrawlerStore } from '@/stores/crawler'
  import {
    ButtonVariant,
    ChButton,
    ChIcon,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'

  const { orgId, nestId } = useRouteParams()

  const props = defineProps({
    resource: {
      type: Object,
      required: true,
    },
  })

  const { t } = useI18n()
  const resourceStore = useResourceStore()
  const crawlerStore = useCrawlerStore()
  const globalStore = useGlobalStore()

  const showDeleteDialog = ref(false)
  const title = ref(props.resource.title)

  const isPDF = computed(() => props.resource.type === 'PDF')
  const isText = computed(() => props.resource.type === 'TEXT')
  const isURL = computed(() => props.resource.type == 'WEBPAGE')
  const date = computed(() =>
    isPDF.value
      ? `${t('nest.card.updated')}${moment(props.resource.updatedAt).format(
          'DD.MM.YYYY',
        )}`
      : `${t('nest.card.created')}${moment(props.resource.updatedAt).format(
          'DD.MM.YYYY',
        )}`,
  )
  const statusText = computed(() => {
    if (props.resource.status === 'DONE') {
      return t('nest.card.statuses.live')
    } else if (
      props.resource.status === 'IN_PROGRESS' ||
      props.resource.status === 'PENDING'
    ) {
      return t('nest.card.statuses.pending')
    } else if (props.resource.status === 'DRAFT') {
      return t('nest.card.statuses.draft')
    } else {
      return t('nest.card.statuses.failed')
    }
  })
  const statusColor = computed(() => {
    if (props.resource.status === 'DONE') {
      return 'bg-green-500'
    } else if (
      props.resource.status === 'IN_PROGRESS' ||
      props.resource.status === 'PENDING'
    ) {
      return 'bg-yellow-500 pulse'
    } else if (props.resource.status === 'DRAFT') {
      return 'bg-gray-400'
    } else {
      return 'bg-red-500'
    }
  })

  const saveTitle = async () => {
    if (props.resource.type === 'WEBPAGE') {
      await crawlerStore.actions.updateWebScraper(
        orgId.value,
        nestId.value,
        props.resource.webScraperId,
        {
          title: title.value,
        },
      )
    } else {
      await resourceStore.actions.updateResourceTitle(
        orgId.value,
        nestId.value,
        props.resource.dataSourceId,
        title.value,
      )
    }
    await fetchData()
  }

  const fetchData = async () => {
    await resourceStore.actions.fetchResources(
      orgId.value,
      nestId.value,
      1,
      localStorage.getItem('resourcePerPage'),
    )
    await crawlerStore.actions.fetchWebScrapers(orgId.value, nestId.value)
  }

  const deleteResource = async () => {
    if (props.resource.type === 'WEBPAGE') {
      await crawlerStore.actions.deleteScraper(
        orgId.value,
        nestId.value,
        props.resource.webScraperId,
      )
    } else {
      await resourceStore.actions.deleteResource(
        orgId.value,
        nestId.value,
        props.resource.dataSourceId,
      )
    }
    await fetchData()
    toggleDeleteDialog()
  }

  const getFilePath = () => {
    resourceStore.actions.fetchResourceUrl(
      orgId.value,
      nestId.value,
      props.resource.dataSourceId,
    )
  }

  const toggleDeleteDialog = () =>
    (showDeleteDialog.value = !showDeleteDialog.value)

  const toggleResourceEditorDialog = () => {
    resourceStore.actions.fetchResourceText(
      orgId.value,
      nestId.value,
      props.resource.dataSourceId,
    )
    globalStore.mutations.toggleResourceEditorDialog()
  }

  const closeEdit = () => {
    title.value = props.resource.title
  }

  // watch(props.resource, () => {
  //   title.value = props.resource.title
  // })
</script>

<style scoped lang="scss">
  .pulse {
    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 5px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 5px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 5px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
</style>
