<template>
  <div class="d-flex flex-column">
    <div class="d-flex mb-12 position-relative">
      <img class="mx-auto" :width="90" :height="90" src="@/assets/logo.svg" />
    </div>
    <h2 class="text-h3 mb-4 text-white text-center">
      {{ $t('reset.request.title') }}
    </h2>
    <p class="text-caption text-center text-gray-400">
      {{ $t('reset.request.description') }}
    </p>
    <v-btn
      :to="{ name: 'auth' }"
      variant="plain"
      color="white"
      class="mx-auto mt-4"
      >{{ $t('reset.request.back') }}</v-btn
    >
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
