export default (state: any) => {
  const setMembers = (payload: any) => {
    state.members = payload
  }

  const setMember = (payload: any) => {
    state.member = payload
  }

  const setCurrent = (payload: any) => {
    state.current = payload
  }

  return {
    setMembers,
    setMember,
    setCurrent,
  }
}
