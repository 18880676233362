import axios from 'axios'
import { useAuthStore } from '@/stores/auth'

const setup = () => {
  const authStore = useAuthStore()

  let isRefreshing = false
  let refreshPromise: Promise<any> | null = null

  axios.interceptors.request.use(
    (config) => {
      config.baseURL = import.meta.env.VITE_API
      const token = localStorage.getItem('accessToken')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      // Add a retry count in the originalConfig
      originalConfig._retryCount = originalConfig._retryCount || 0

      // Check if retry count exceeds limit
      if (originalConfig._retryCount > 1) {
        return Promise.reject(err)
      }

      // Increment retry count
      originalConfig._retryCount++

      if (
        originalConfig.url !== '/authorization/generate-tokens' &&
        originalConfig.url !== '/authorization/refresh-tokens' &&
        originalConfig.url !== '/authorization/nullify-tokens' &&
        err.response
      ) {
        // Access Token was expired or insufficient permissions
        if (err.response.status === 401 || err.response.status === 403) {
          if (!isRefreshing) {
            isRefreshing = true
            refreshPromise = authStore.actions
              .refreshTokens({
                refreshToken: `Bearer ${localStorage.getItem('refreshToken')}`,
              })
              .then(() => {
                isRefreshing = false
              })
              .catch(() => {
                isRefreshing = false
              })
          }

          await refreshPromise

          return axios(originalConfig)
        }
      }

      return Promise.reject(err)
    },
  )
}

export default setup
