<template>
  <div class="table-wrapper d-flex flex-column bg-whiteBg pa-8 rounded-lg">
    <div
      class="table-wrapper__filter w-100 d-flex align-center justify-lg-space-between mb-8"
    >
      <v-text-field
        class="flex-0-0 w-50"
        variant="outlined"
        color="textSecondary"
        placeholder="Search for account names or email addresses ..."
        hide-details
      />
      <v-btn color="primary" @click="toggleInviteMemberDialog">Invite</v-btn>
    </div>
    <v-table class="text-body-1">
      <thead>
        <tr>
          <th class="text-left text-gray-400">Account name</th>
          <th class="text-left text-gray-400">Owner Email</th>
          <th class="text-left text-gray-400">Deleted</th>
          <th class="text-left text-gray-400">Created At</th>
          <th />
        </tr>
      </thead>
      <tbody v-if="users.length > 0">
        <tr v-for="item in users" :key="item.id">
          <td
            class="history-table__conversation-starter"
            @click="showDetails(item)"
          >
            {{ fullName(item) }}
          </td>
          <td class="text-gray-400">{{ item.email }}</td>
          <td>{{ item.isDeleted }}</td>
          <td>
            <div class="date-time d-flex flex-column">
              <span class="date">{{
                moment(item.createdAt).format('DD.MM.YYYY') || '---'
              }}</span>
              <span class="time text-gray-400 text-caption d-flex align-center">
                <v-icon class="mr-1 text-sm-caption"
                  >mdi-clock-time-four-outline</v-icon
                >{{ moment(item.createdAt).format('HH:mm') }}</span
              >
            </div>
          </td>
          <td>
            <v-btn
              class="mr-2"
              density="compact"
              icon="mdi-pencil"
              color="info"
              variant="tonal"
              @click="editMember(item)"
            />
            <v-btn
              density="compact"
              icon="mdi-trash-can-outline"
              color="error"
              variant="tonal"
              @click="removeMember"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4" class="text-center">No dialogs</td>
        </tr>
      </tbody></v-table
    >
  </div>
</template>

<script setup lang="ts">
  import moment from 'moment'
  import { useGlobalStore } from '@/stores/global'
  import { useUserStore } from '@/stores/user'
  import { computed } from 'vue'
  import { useMemberStore } from '@/stores/members'
  import { useRoute } from 'vue-router'

  const route = useRoute()
  const globalStore = useGlobalStore()
  const userStore = useUserStore()
  const memberStore = useMemberStore()

  const orgId = computed(() => route.params.id as string)

  // const members = computed(() => memberStore.getters.members())
  const users = computed(() => userStore.getters.users())

  const fullName = (user: any) => {
    return user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : '---'
  }

  const showDetails = (user: any) => {
    userStore.mutations.setSelected(user)
    memberStore.mutations.setCurrent(user)
    globalStore.mutations.showAdminDetailsSidebar()
  }

  const toggleInviteMemberDialog = () => {
    globalStore.mutations.toggleInviteMemberDialog()
  }

  const editMember = (member: any) => {
    memberStore.mutations.setCurrent(member)
    globalStore.mutations.toggleEditMemberDialog()
  }

  const removeMember = async (memberId: number) => {
    await memberStore.actions.removeMember(orgId.value, memberId.toString())
    await memberStore.actions.fetchMembers(orgId.value)
  }
</script>

<style scoped lang="scss">
  .history-table {
    &__conversation-starter {
      cursor: pointer;
    }
  }
</style>
