export default (state: any) => {
  const item = () => {
    return state.item
  }

  const answers = () => {
    return state.answers
  }

  const question = () => {
    return state.question
  }

  const selectedAnswer = () => {
    return state.selectedAnswer
  }

  return {
    item,
    answers,
    question,
    selectedAnswer,
  }
}
