<template>
  <div class="overlay animate__animated animate__faster animate__fadeIn">
    <div class="empty-overlay" @click="close" />
    <div
      class="interactions animate__animated animate__faster animate__slideInRight"
      :style="'max-width: ' + sidebarWidth + 'px'"
    >
      <ChButton
        class="interactions__close-icon"
        icon="dismiss"
        :color="ColorScheme.SECONDARY"
        :variant="ButtonVariant.CLEAR"
        @click="close"
      />
      <div class="flex items-start px-3 pt-12 pb-4 gap-4">
        <ChButton
          :class="{ 'rotate-180': expand }"
          icon="expand"
          :color="ColorScheme.SECONDARY"
          :size="ButtonSize.SM"
          :variant="ButtonVariant.CLEAR"
          @click="toggleExpand"
        />
        <HistoryInteractionsHeader :current-dialog="props.currentDialog" />
      </div>

      <ul class="interactions__list">
        <HistoryInteraction
          v-for="interaction in interactions"
          :key="interaction.interactionId"
          :interaction="interaction"
          @improve="improveAnswer"
        />
      </ul>
      <ChButton
        v-if="page * 50 < interactionsCount"
        class="rounded-none"
        @click="loadMore"
        >{{ $t('history.content.interactions.load-more') }}</ChButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import type { PropType } from 'vue'
  import { computed, ref } from 'vue'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useGlobalStore } from '@/stores/global'
  import HistoryInteractionsHeader from '@/components/app/history/HistoryInteractionsHeader.vue'
  import HistoryInteraction from '@/components/app/history/HistoryInteraction.vue'
  import { useChatStore } from '@/stores/chat'
  import type { Interaction } from '@/types/interfaces'
  import { useRouteParams } from '@/composables/route-params'
  import {
    ButtonSize,
    ButtonVariant,
    ChButton,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'

  const chatStore = useChatStore()
  const globalStore = useGlobalStore()
  const dialogStore = useDialogsStore()

  const props = defineProps({
    currentDialog: {
      type: Object as PropType<any | null>,
      required: true,
    },
  })

  const emits = defineEmits(['update:show', 'close'])

  const { orgId, nestId } = useRouteParams()

  const expand = ref(false)
  const currentInteraction = ref<Interaction | null>(null)
  const page = ref<number>(1)

  props.currentDialog &&
    dialogStore.actions.fetchInteractions(
      orgId.value,
      nestId.value,
      props.currentDialog.dialogId.toString(),
      page.value,
    )

  const interactions = computed<Interaction[]>(() =>
    dialogStore.getters.interactions(),
  )
  const interactionsCount = computed<number>(() =>
    dialogStore.getters.interactionsCount(),
  )

  const close = () => {
    emits('close')
  }

  const sidebarWidth = computed(() => {
    return expand.value ? 900 : 400
  })

  const loadMore = () => {
    page.value++
    props.currentDialog &&
      dialogStore.actions.fetchInteractions(
        orgId.value,
        nestId.value,
        props.currentDialog.dialogId.toString(),
        page.value,
      )
  }

  const toggleExpand = () => {
    expand.value = !expand.value
  }

  const improveAnswer = (interaction: Interaction) => {
    currentInteraction.value = interaction
    chatStore.mutations.setAnswers(interaction.answers)
    chatStore.mutations.setQuestion(interaction.question.text)
    dialogStore.mutations.setInteraction(interaction)
    globalStore.mutations.toggleImproveAnswerDialog()
  }
</script>

<style lang="scss" scoped>
  .overlay {
    @apply z-30 fixed inset-0 w-full h-screen flex justify-center items-center;
  }

  .empty-overlay {
    @apply absolute inset-0 w-full h-full bg-black bg-opacity-30 dark:bg-opacity-50;
  }

  .interactions {
    @apply w-full flex flex-col h-screen bg-white dark:bg-gray-900 fixed right-0 top-0 z-50 shadow-2xl transition-all duration-500;

    &__close-icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 1;
    }

    &__list {
      @apply flex-1 overflow-y-auto;
    }
  }

  .history {
    &__logo {
      min-width: 32px;
      height: 32px;
    }
  }
</style>
