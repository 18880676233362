<template>
  <component :is="route?.meta?.layoutComponent">
    <router-view />
  </component>
</template>

<script lang="ts" setup>
  import { useRoute } from 'vue-router'
  import { useDark } from '@vueuse/core'
  import { onMounted } from 'vue'

  const route = useRoute()
  const isDark = useDark()

  onMounted(() => {
    console.info('dark - ', isDark.value)
  })
</script>
