<template>
  <form @submit.prevent="create" class="auth-form">
    <div class="flex mb-4 relative">
      <img
        class="mx-auto"
        :width="40"
        :height="40"
        :src="imgLoader('logo', 'svg')"
        alt="logo"
      />
    </div>
    <ChInput v-model="email" :label="$t('auth.email')" class="mb-4" required />
    <ChButton class="mb-4" :disabled="!checkEmail" type="submit">
      Create User
    </ChButton>
  </form>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import imgLoader from '@/utils/img-loader'
  import { useOrganizationStore } from '@/stores/organizations'
  import { ChButton, ChInput } from '@chatlyncom/chatlyn-ui-components'

  const authStore = useAuthStore()
  const organizationStore = useOrganizationStore()

  const email = ref<string>('')

  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const checkEmail = computed(() => {
    return emailRegex.test(email.value)
  })

  const create = async () => {
    const data = await authStore.actions.createUser({
      email: email.value,
      isSuperAdmin: false,
    })
    await organizationStore.actions.createOrganization({
      organizationName: 'Default Organization',
      fieldOfActivity: 'OTHER',
      ownerId: data.userId,
    })
  }
</script>

<style lang="scss" scoped>
  .auth-form {
    @apply flex flex-col content-stretch justify-center w-96 bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6;
  }
</style>
