<template>
  <div
    class="chat bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-xl"
  >
    <div class="chat__container overflow-hidden">
      <ul v-if="intents.length > 0" class="flex-1 p-0 overflow-y-auto">
        <li
          v-for="(intent, index) in intents"
          :key="index + '-intent'"
          class="p-0 flex flex-col w-full"
        >
          <div
            class="chat__header bg-white dark:bg-gray-950 py-8 flex items-center"
          >
            <p class="chat__title flex-1">
              {{ intent.question }}
            </p>
          </div>
          <div class="chat__answer my-8 w-full flex align-start">
            <img
              :src="imgLoader('logo-svg', 'svg')"
              alt="answers logo"
              class="answers__logo bg-white dark:bg-gray-950 w-9 h-9 p-2 mt-1 rounded mr-4"
            />
            <div
              v-if="intent.answers.length > 0"
              class="answers__content flex items-center w-full"
            >
              <p class="answers__text flex-1 mr-8 break-all">
                {{ intent.answers[0].text }}
              </p>
              <ChButton @click="improve(intent)">
                {{ $t('testing.content.improve') }}
              </ChButton>
            </div>
            <div v-else class="answers__content flex items-center w-full">
              <p class="answers__text flex-1 mr-8">
                {{ $t('testing.content.no-answer') }}
              </p>
              <ChButton @click="improve(intent)">
                {{ $t('testing.content.add') }}
              </ChButton>
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="flex items-center justify-center flex-1">
        <EmptyState
          img-name="NoMessages"
          :title="$t('testing.content.empty')"
        />
      </div>
      <div class="chat__actions flex items-center w-full px-8">
        <button
          v-if="intents.length > 0"
          class="clear-btn flex items-center bg-woot-500 rounded-full text-white py-2 overflow-hidden relative mr-4"
          @click="clear"
        >
          <ChIcon :size="20" class="mx-2.5" icon="new-message" />
          <span class="clear-btn__text ml-2 mr-2 absolute">{{
            $t('testing.content.new')
          }}</span>
        </button>
        <form
          class="my-4 mx-auto max-w-3xl flex-1 flex gap-4 items-center"
          @submit.prevent="askQuestion"
        >
          <AiTextarea
            v-model="question"
            :disabled="disabled"
            class="flex-grow"
            :placeholder="$t('testing.content.type-question')"
            @keydown.enter.exact.prevent="askQuestion"
          >
            <template #appendIcon>
              <ChButton
                class="m-2"
                icon="send"
                type="submit"
                :size="ButtonSize.SM"
                rounded
                :loading="disabled"
                :disabled="!question || disabled"
              />
            </template>
          </AiTextarea>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { showErrors } from '@/utils/errors'
  import { useNestStore } from '@/stores/nests'
  import { useDialogsStore } from '@/stores/dialogs'
  import AiTextarea from '@/components/ui/AiTextarea.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import imgLoader from '@/utils/img-loader'
  import { useChatStore } from '@/stores/chat'
  import { useGlobalStore } from '@/stores/global'
  import {
    ButtonSize,
    ChButton,
    ChIcon,
  } from '@chatlyncom/chatlyn-ui-components'

  const nestStore = useNestStore()
  const chatStore = useChatStore()
  const globalStore = useGlobalStore()
  const dialogStore = useDialogsStore()

  const disabled = ref<boolean>(false)
  const intents = ref<any>([])
  const selectedIntent = ref(null)
  const question = ref<string>('')

  const secret = computed(() => nestStore.getters.secret())
  const dialog = computed(() => dialogStore.getters.trainingDialog())

  const improve = (intent: any) => {
    selectedIntent.value = intent
    chatStore.mutations.setAnswers(intent.answers)
    chatStore.mutations.setQuestion(intent.question)
    globalStore.mutations.toggleImproveAnswerDialog()
  }

  const askQuestion = async () => {
    disabled.value = true
    try {
      const data = await chatStore.actions.askQuestion({
        question: question.value,
        secretKey: secret.value,
        dialogId: dialog.value.dialogId,
        training: true,
      })

      intents.value.push({
        question: question.value,
        answers: data,
      })
      question.value = ''
    } catch (error) {
      showErrors((error as any).response.data.message)
    } finally {
      disabled.value = false
    }
  }

  const clear = () => {
    intents.value = []
    question.value = ''
  }
</script>

<style scoped lang="scss">
  .chat {
    display: flex;
    flex: 1;
    height: 100%;
    max-height: calc(100vh - 120px);

    &__container {
      flex: 1;
      display: flex;
      flex-direction: column;
      > div {
        width: 100%;
      }
    }

    &__header {
      padding-left: 140px;
      padding-right: 140px;
    }

    &__answer {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .clear-btn {
    width: 40px;
    height: 40px;
    transition: all 0.25s ease-in-out;

    .clear-btn__text {
      right: 0.5rem;
      top: 0.5rem;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    &:hover {
      width: 190px;

      .clear-btn__text {
        opacity: 1;
      }
    }
  }

  :deep(input) {
    @apply border-none;
  }
</style>
