<template>
  <div class="main">
    <div class="container flex flex-col">
      <InfoBox
        :title="$t('training.info-box.title')"
        :description="$t('training.info-box.description')"
        img-name="feedback-message"
        info-box-name="trainingInfoBox"
        route-name="training"
        show-close-icon
      />
      <div class="flex gap-4 w-full flex-1">
        <TrainingSidebar />
        <TrainingContainer />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useNestStore } from '@/stores/nests'
  import { useRouteParams } from '@/composables/route-params'
  import { useDialogsStore } from '@/stores/dialogs'
  // import { useIntentStore } from '@/stores/intents'
  import { computed, onMounted } from 'vue'
  import TrainingSidebar from '@/components/app/training/TrainingSidebar.vue'
  import TrainingContainer from '@/components/app/training/TrainingContainer.vue'
  import { useChatStore } from '@/stores/chat'
  import { useIntentStore } from '@/stores/intents'
  import InfoBox from '@/components/app/global/InfoBox.vue'

  const chatStore = useChatStore()
  const nestStore = useNestStore()
  const intentStore = useIntentStore()
  const dialogStore = useDialogsStore()

  const { orgId, nestId } = useRouteParams()

  const secret = computed(() => nestStore.getters.secret())
  // const trainingDialog = computed(() => dialogStore.getters.trainingDialog())

  const fetchData = async () => {
    // await intentStore.actions.fetchIntents(orgId.value, nestId.value, true)
    await nestStore.actions.fetchNest(orgId.value, nestId.value)
    await nestStore.actions.fetchSecret(orgId.value, nestId.value)
    await dialogStore.actions.fetchTrainingDialog(orgId.value, nestId.value)

    if (!secret.value) {
      await nestStore.actions.generateSecret(orgId.value, nestId.value)
    }

    // TODO: delete if not needed
    // if (!trainingDialog.value) {
    //   await dialogStore.actions.createDialog(orgId.value, nestId.value, {
    //     guest: {
    //       identifier: localStorage.getItem('email'),
    //     },
    //     secretKey: secret.value,
    //     training: true,
    //   })
    // }
  }

  const clear = () => {
    chatStore.mutations.setQuestion('')
    chatStore.mutations.setAnswers([])

    intentStore.mutations.setResetedIntent(null)
    intentStore.mutations.setIntent(null)
    intentStore.mutations.setSavedIntent(null)
  }

  onMounted(() => {
    clear()
    fetchData()
  })
</script>

<style scoped lang="scss"></style>
