import axios from 'axios'

export default {
  askQuestion: (data: any) => {
    return axios.post('/chatbot/get-answers', data)
  },
  startConversation: (data: any) => {
    return axios.post('/chatbot/start-conversation', data)
  },
}
