<template>
  <main class="main">
    <div class="container flex items-center justify-center">
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <EmptyState
          v-if="hasNests && !nestFlagLoading"
          :title="$t('home.title')"
        />
      </transition>
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <WelcomeWrapper v-if="!nestFlagLoading && !hasNests" />
      </transition>
    </div>
  </main>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'
  import { useNestStore } from '@/stores/nests'
  import WelcomeWrapper from '@/components/app/home/WelcomeWrapper.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'

  const nestStore = useNestStore()

  const nests = computed(() => nestStore.getters.nests())
  const nestFlagLoading = computed(() => nestStore.getters.flagLoading())
  const hasNests = computed(() => nests.value.length > 0)
</script>

<style lang="scss" scoped>
  .nest-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .nest-container__title {
    color: inherit;
    text-align: center;
    font-size: 200%;
    font-weight: 300;
  }
</style>
