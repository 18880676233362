<template>
  <div class="crawler-urls">
    <CrawlerActions
      v-model="search"
      :empty="empty"
      @rerun="rerun"
      @delete="toggleDeleteDialog"
      @add="add"
    />
    <!--    <ChTable-->
    <!--      v-model="selectedRows"-->
    <!--      row-key="webpageId"-->
    <!--      :headers="tableHeader"-->
    <!--      :table-data="webpages.items"-->
    <!--    >-->
    <!--      <template #default="{ id, column, cellValue, rowKey }">-->
    <!--        {{ id }}{{ column }}{{ cellValue }}{{ rowKey }}-->
    <!--      </template>-->
    <!--    </ChTable>-->
    <CrawlerTable
      :count="webpages.count"
      :rows="webpages.items"
      @change-page="changePage"
      @select="setSelects"
      @rerun="rerunRow"
      @delete="toggleDeleteDialog"
      @sort="setSort"
    />
  </div>
  <AiDialog
    v-if="addUrlDialogShowing"
    title="Add new URLs"
    @close="toggleAddUrlDialog"
  >
    <AddUrl @add="addUrls" @cancel="toggleAddUrlDialog" />
  </AiDialog>
  <AiDialog
    v-if="deleteDialogShowing"
    title="Delete selected URLs"
    @close="toggleDeleteDialog"
  >
    <div class="delete-dialog">
      <ChButton
        class="delete-dialog__btn"
        :color="ColorScheme.ALERT"
        @click="deleteSelected"
        >Delete</ChButton
      >
      <ChButton
        class="delete-dialog__btn"
        :color="ColorScheme.SECONDARY"
        @click="toggleDeleteDialog"
        >Cancel</ChButton
      >
    </div>
  </AiDialog>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import CrawlerActions from '@/components/app/crawler/view/urls/CrawlerActions.vue'
  import CrawlerTable from '@/components/app/crawler/view/urls/CrawlerTable.vue'
  import { useCrawlerStore } from '@/stores/crawler'
  import { useRouteParams } from '@/composables/route-params'
  import AiDialog from '@/components/ui/AiDialog.vue'
  import AddUrl from '@/components/app/crawler/dialog/AddUrl.vue'
  import {
    ChButton,
    // ChTable,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'
  import { useRouter } from 'vue-router'
  import type { Webpage } from '@/types/interfaces'
  import { debounce } from 'lodash'

  interface Sort {
    orderBy: string
    orderDirection: string
  }

  const router = useRouter()
  const crawlerStore = useCrawlerStore()

  const { query, params, orgId, nestId } = useRouteParams()

  // const tableHeader = [
  //   {
  //     name: 'URL',
  //     value: 'url',
  //     width: 'auto',
  //   },
  //   {
  //     name: 'Status',
  //     value: 'status',
  //     width: '160px',
  //   },
  //   {
  //     name: 'Last run',
  //     value: 'lastRun',
  //     width: '160px',
  //   },
  //   {
  //     name: '',
  //     value: 'actions',
  //     width: '40px',
  //   },
  // ]

  const addUrlDialogShowing = ref(false)
  const deleteDialogShowing = ref(false)
  const search = ref<string>('')
  const selectedRows = ref<number[]>([])
  const selectedRowId = ref<number | null>(null)

  const empty = computed(() => selectedRows.value.length === 0)
  // const scraper = computed(() => crawlerStore.getters.scraper())
  const webpages = computed(() => crawlerStore.getters.webpages())

  const toggleAddUrlDialog = () => {
    addUrlDialogShowing.value = !addUrlDialogShowing.value
  }

  const toggleDeleteDialog = (selectedRow?: number | undefined) => {
    selectedRowId.value = selectedRow || null
    deleteDialogShowing.value = !deleteDialogShowing.value
  }

  const setSort = (sort: Sort) => {
    crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
      search.value,
      sort.orderBy,
      sort.orderDirection,
    )
  }

  const addUrls = async (urls: string[]) => {
    const urlsTypes = await crawlerStore.actions.addPages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      urls,
    )

    await crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
    )

    if (!urlsTypes.duplicateURLs.length && !urlsTypes.invalidURLs.length) {
      toggleAddUrlDialog()
      crawlerStore.mutations.setUrlsTypes({
        duplicateURLs: [],
        invalidURLs: [],
        webpages: [],
      })
    }
  }

  const rerun = async () => {
    await crawlerStore.actions.runScraper(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      selectedRows.value,
    )
    await crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
    )
  }

  const rerunRow = async (row: Webpage) => {
    await crawlerStore.actions.runScraper(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      [row.webpageId],
    )
    await crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
    )
  }

  const deleteSelected = async () => {
    await crawlerStore.actions.deleteScraperPages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      selectedRowId.value ? [selectedRowId.value] : selectedRows.value,
    )
    await crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
    )

    selectedRowId.value = null
    toggleDeleteDialog()
  }

  // const deleteRow = async (row: Webpage) => {
  //   await crawlerStore.actions.deleteScraperPages(
  //     orgId.value,
  //     nestId.value,
  //     params.scraperId as string,
  //     [row.webpageId],
  //   )
  //   await crawlerStore.actions.fetchScraperWebpages(
  //     orgId.value,
  //     nestId.value,
  //     params.scraperId as string,
  //     Number(query.value.page) | 1,
  //   )
  //   toggleDeleteDialog()
  // }

  const add = () => {
    toggleAddUrlDialog()
  }

  const setSelects = (selected: number[]) => {
    selectedRows.value = selected
  }

  const changePage = (page: number) => {
    crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      page,
    )

    router.push({
      query: {
        ...query.value,
        page,
      },
    })
  }

  const debouncedFetch = debounce(() => {
    crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
      search.value,
    )
  }, 500)

  watch(search, () => {
    debouncedFetch()
  })
</script>

<style scoped lang="scss">
  .delete-dialog {
    @apply flex items-center justify-end p-4;

    &__btn {
      &:first-of-type {
        @apply mr-4;
      }
    }
  }
</style>
