<template>
  <form class="form w-full" @submit.prevent="updatePassword">
    <div
      class="w-full bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg shadow-lg p-4 flex flex-col space-y-4 items-start"
    >
      <h2 class="profile__title">{{ $t('profile.content.password.title') }}</h2>
      <ChInput
        v-model="passwords.password"
        :label="$t('profile.content.password.password')"
        class="w-full"
        id="password"
        :type="InputType.PASSWORD"
        @input="passwordValidation"
      />
      <ChInput
        v-model="passwords.newPassword"
        :label="$t('profile.content.password.repeat')"
        class="w-full"
        id="newPassword"
        :type="InputType.PASSWORD"
        @input="passwordValidation"
      />
      <ul v-if="showErrors" class="errors flex flex-col text-sm">
        <li
          class="errors__error"
          :class="errors.password ? 'text-red-400' : 'text-green-400'"
        >
          {{ $t('profile.content.password.validation.password') }}
        </li>
        <li
          class="errors__error"
          :class="errors.repeat ? 'text-red-400' : 'text-green-400'"
        >
          {{ $t('profile.content.password.validation.repeat') }}
        </li>
        <li
          class="errors__error"
          :class="errors.uppercase ? 'text-red-400' : 'text-green-400'"
        >
          {{ $t('profile.content.password.validation.uppercase') }}
        </li>
        <li
          class="errors__error"
          :class="errors.lowercase ? 'text-red-400' : 'text-green-400'"
        >
          {{ $t('profile.content.password.validation.lowercase') }}
        </li>
        <li
          class="errors__error"
          :class="errors.number ? 'text-red-400' : 'text-green-400'"
        >
          {{ $t('profile.content.password.validation.number') }}
        </li>
        <li
          class="errors__error"
          :class="errors.special ? 'text-red-400' : 'text-green-400'"
        >
          {{ $t('profile.content.password.validation.special') }}
        </li>
      </ul>
      <ChButton type="submit" :disabled="isButtonDisabled">{{
        $t('profile.content.password.update')
      }}</ChButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
  import { computed, reactive } from 'vue'
  import { useUserStore } from '@/stores/user'
  import {
    ChButton,
    ChInput,
    InputType,
  } from '@chatlyncom/chatlyn-ui-components'

  const errors = reactive({
    password: true,
    repeat: true,
    uppercase: true,
    lowercase: true,
    number: true,
    special: true,
  })

  const isButtonDisabled = computed(() => {
    return Object.values(errors).some((error) => error)
  })

  const showErrors = computed(() => {
    return passwords.password.length > 0 || passwords.newPassword.length > 0
  })

  const passwordValidation = () => {
    errors.password = passwords.password.length < 8
    errors.repeat = passwords.password !== passwords.newPassword
    errors.uppercase = !/[A-Z]/.test(passwords.password)
    errors.lowercase = !/[a-z]/.test(passwords.password)
    errors.number = !/[0-9]/.test(passwords.password)
    errors.special = !/[!@#$%^&*(),.?":{}|<>]/.test(passwords.password)
  }

  const userStore = useUserStore()
  const passwords = reactive({
    password: '',
    newPassword: '',
  })

  const me = computed(() => userStore.getters.user())

  const updatePassword = () => {
    userStore.actions.changeUserPassword(me.value.userId, {
      password: passwords.password,
    })
  }
</script>

<style lang="scss" scoped></style>
