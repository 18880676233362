<template>
  <div class="flex items-center w-full justify-end mt-4 gap-1">
    <!-- TODO: Hide for now -->
    <!--    <div class="per-page flex items-center mr-4">-->
    <!--      <p class="text-sm mr-2 whitespace-nowrap text-gray-400">-->
    <!--        {{ $t('history.content.footer.per-page') }}-->
    <!--      </p>-->
    <!--      <select-->
    <!--        v-model="perPage"-->
    <!--        class="form-control text-sm ps-2 flex-grow-0 w-auto"-->
    <!--      >-->
    <!--        <option-->
    <!--          v-for="itemsLimit in [10, 20, 30, 40, 50]"-->
    <!--          :key="itemsLimit"-->
    <!--          :value="itemsLimit"-->
    <!--        >-->
    <!--          {{ itemsLimit }}-->
    <!--        </option>-->
    <!--      </select>-->
    <!--    </div>-->
    <ChButton
      rounded
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      icon="chevron-double-left"
      @click="goFirst"
      :disabled="paginationPage === 1"
    />
    <ChButton
      rounded
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      icon="chevron-left"
      @click="goPrevious"
      :disabled="paginationPage === 1"
    />
    <ChButton
      rounded
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      icon="chevron-right"
      @click="goNext"
      :disabled="paginationPage >= totalPages"
    />
    <ChButton
      rounded
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      icon="chevron-double-right"
      @click="goLast"
      :disabled="paginationPage >= totalPages"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed, watch } from 'vue'
  import { useDialogsStore } from '@/stores/dialogs'
  import {
    ButtonVariant,
    ChButton,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'

  const dialogStore = useDialogsStore()

  const props = defineProps({
    paginationPage: {
      type: Number,
      default: 1,
    },
    paginationLimit: {
      type: Number,
      default: 10,
    },
  })

  const emits = defineEmits(['update:paginationPage', 'update:paginationLimit'])

  const perPage = computed({
    get() {
      return props.paginationLimit
    },
    set(value) {
      emits('update:paginationLimit', value)
    },
  })

  const count = computed(() => dialogStore.getters.dialogsCount())

  const totalPages = computed(() =>
    Math.ceil(count.value / props.paginationLimit),
  )

  watch(perPage, (value) => {
    localStorage.setItem('resourcePerPage', value.toString())
  })

  const goFirst = () => {
    emits('update:paginationPage', 1)
  }

  const goPrevious = () => {
    emits('update:paginationPage', props.paginationPage - 1)
  }

  const goNext = () => {
    emits('update:paginationPage', props.paginationPage + 1)
  }

  const goLast = () => {
    emits(
      'update:paginationPage',
      Math.ceil(count.value / props.paginationLimit),
    )
  }
</script>

<style scoped lang="scss"></style>
