import axios from 'axios'
import { addParams } from '@/utils/route-helpers'

export default {
  fetchOrganizationsApiKeys: (organizationId: string) => {
    return axios.get(
      `/organizations/${organizationId}/api-tokens?limit=100&page=0`,
    )
  },
  createOrganizationsApiKey: (organizationId: string) => {
    return axios.post(`/organizations/${organizationId}/api-tokens`)
  },
  deleteOrganizationsApiKey: (organizationId: string, apiKeyId: string) => {
    return axios.delete(
      `/organizations/${organizationId}/api-tokens/${apiKeyId}`,
    )
  },
  fetchOrganizations: () => {
    return axios.get(`/organizations`)
  },
  fetchOrganization: (organizationId: string) => {
    return axios.get(`/organizations/${organizationId}`)
  },
  createOrganization: (data: any) => {
    return axios.post(`/organizations`, data)
  },
  deleteOrganization: (organizationId: string) => {
    return axios.delete(`/organizations/${organizationId}`)
  },
  updateOrganization: (organizationId: string, data: any) => {
    return axios.patch(`/organizations/${organizationId}`, data)
  },

  deleteNestSoft: (organizationId: string, nestId: string) => {
    return axios.patch(
      `/organizations/${organizationId}/nests/${nestId}/delete`,
    )
  },
  restoreNestSoft: (organizationId: string, nestId: string) => {
    return axios.patch(
      `/organizations/${organizationId}/nests/${nestId}/restore`,
    )
  },
  fetchNestOptions: (organizationId: string, nestId: string) => {
    return axios.get(`/organizations/${organizationId}/nests/${nestId}/options`)
  },
  fetchSecret: (organizationId: string, nestId: string) => {
    return axios.get(`/organizations/${organizationId}/nests/${nestId}/secret`)
  },
  generateSecret: (organizationId: string, nestId: string) => {
    return axios.post(`/organizations/${organizationId}/nests/${nestId}/secret`)
  },
  verifySecret: (data: any) => {
    return axios.post(`/authorization/verify-secret-key`, data)
  },
  revokeSecret: (organizationId: string, nestId: string) => {
    return axios.delete(
      `/organizations/${organizationId}/nests/${nestId}/secret`,
    )
  },
  fetchIntents: (organizationId: string, nestId: string) => {
    return axios.get(`/organizations/${organizationId}/nests/${nestId}/intents`)
  },
  fetchIntent: (organizationId: string, nestId: string, intentId: string) => {
    return axios.get(
      `/organizations/${organizationId}/nests/${nestId}/intents/${intentId}`,
    )
  },
  createIntent: (organizationId: string, nestId: string, data: any) => {
    return axios.post(
      `/organizations/${organizationId}/nests/${nestId}/intents`,
      data,
    )
  },
  updateIntent: (organizationId: string, nestId: string, data: any) => {
    return axios.patch(
      `/organizations/${organizationId}/nests/${nestId}/intents`,
      data,
    )
  },
  deleteIntent: (organizationId: string, nestId: string, intentId: string) => {
    return axios.delete(
      `/organizations/${organizationId}/nests/${nestId}/intents/${intentId}`,
    )
  },
  fetchFileUrl: (
    organizationId: string,
    nestId: string,
    dataSourceId: string,
  ) => {
    return axios.get(
      `/organizations/${organizationId}/nests/${nestId}/files/${dataSourceId}`,
    )
  },
  uploadFile: (organizationId: string, nestId: string, file: File) => {
    return axios.post(
      `/organizations/${organizationId}/nests/${nestId}/files`,
      file,
    )
  },
  updateFileTitle: (
    organizationId: string,
    nestId: string,
    dataSourceId: string,
    title: any,
  ) => {
    return axios.patch(
      `/organizations/${organizationId}/nests/${nestId}/files/${dataSourceId}`,
      title,
    )
  },
  fetchText: (organizationId: string, nestId: string, dataSourceId: string) => {
    return axios.get(
      `/organizations/${organizationId}/nests/${nestId}/files/text/${dataSourceId}`,
    )
  },
  uploadText: (organizationId: string, nestId: string, data: any) => {
    return axios.post(
      `/organizations/${organizationId}/nests/${nestId}/files/text`,
      data,
    )
  },
  updateText: (
    organizationId: string,
    nestId: string,
    dataSourceId: string,
    data: any,
  ) => {
    return axios.patch(
      `/organizations/${organizationId}/nests/${nestId}/files/text/${dataSourceId}`,
      data,
    )
  },
  processText: (
    organizationId: string,
    nestId: string,
    dataSourceId: string,
  ) => {
    return axios.put(
      `/organizations/${organizationId}/nests/${nestId}/files/process-draft/${dataSourceId}`,
    )
  },
  regenerateDataSourceChunks: (
    organizationId: string,
    nestId: string,
    dataSourceId: string,
  ) => {
    return axios.put(
      `/organizations/${organizationId}/nests/${nestId}/files/regenerate-chunks/${dataSourceId}`,
    )
  },
  fetchDataSources: (
    organizationId: string,
    nestId: string,
    page: number,
    limit: number,
  ) => {
    return axios.get(
      `/organizations/${organizationId}/nests/${nestId}/data-sources?page=${page}&limit=${limit}`,
    )
  },
  deleteDataSource: (
    organizationId: string,
    nestId: string,
    dataSourceId: string,
  ) => {
    return axios.delete(
      `/organizations/${organizationId}/nests/${nestId}/data-sources/${dataSourceId}`,
    )
  },

  // fetchNestOptions: (nestId: string) => {
  //   return axios.get(`/nests/${nestId}/options`)
  // },
  getResources: (id: string) => {
    return axios.get(`/nests/${id}/data-sources?page=1&limit=10`)
  },
  upload: (nestId: string, file: any) => {
    return axios.post(`/nests/${nestId}/files`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  createTextResource: (nestId: string, data: any) => {
    return axios.post(`/nests/${nestId}/files/text`, data)
  },
  getTextResource: (nestId: string, dataSourceId: string | number) => {
    return axios.get(`/nests/${nestId}/files/text/${dataSourceId}`)
  },
  updateTextResource: (nestId: string, dataSourceId: string, data: any) => {
    return axios.patch(`/nests/${nestId}/files/text/${dataSourceId}`, data)
  },
  updateTitleResource: (
    nestId: string,
    dataSourceId: string,
    title: string,
  ) => {
    return axios.patch(`/nests/${nestId}/files/${dataSourceId}`, { title })
  },
  webpage: (id: string, url: string) => {
    return axios.get(`/nests/${id}/data-sources/webpages`, {
      url,
    })
  },
  update: (id: string, data: any) => {
    return axios.post('/nests/' + id, data)
  },
  updateNest: (id: string, data: any) => {
    return axios.patch('/nests/' + id, data)
  },
  // Secret Key
  // generateSecret: (id: string) => {
  //   return axios.post(`/nests/${id}/secret`)
  // },
  getSecret: (id: string) => {
    return axios.get(`/nests/${id}/secret`)
  },
  // revokeSecret: (id: string) => {
  //   return axios.delete(`/nests/${id}/secret`)
  // },
  // Dialogs
  createDialog: (id: string, data: any) => {
    return axios.post(`/nests/${id}/dialogs`, data)
  },
  // createIntent: (nestId: any, newIntent: any) => {
  //   return axios.post(`/nests/${nestId}/intents`, newIntent)
  // },
  getIntents: (nestId: string) => {
    return axios.get(`/nests/${nestId}/intents`)
  },
  getIntent: (nestId: string, intentId: string) => {
    return axios.get(`/nests/${nestId}/intents/${intentId}`)
  },
  // deleteIntent: (nestId: string, intentId: string) => {
  //   return axios.delete(`/nests/${nestId}/intents/${intentId}`)
  // },
  // updateIntent: (nestId: string, intentId: string, data: any) => {
  //   return axios.patch(`/nests/${nestId}/intents/${intentId}`, data)
  // },
  getDataSourceFilePath: (nestId: string | string[], dataSourceId: string) => {
    return axios.get(`/nests/${nestId}/files/${dataSourceId}`)
  },
  deleteResource: (nestId: string, dataSourceId: any) => {
    return axios.delete(`/nests/${nestId}/data-sources/${dataSourceId}`)
  },
  getDialogs: (id: string) => {
    return axios.get(`/nests/${id}/dialogs`)
  },
  getHistoryDialogs: (
    id: string,
    page: string | number,
    limit: string | number,
    name: string,
    identifier: string,
    dateFrom: string,
    dateTo: string,
  ) =>
    axios.get(
      addParams(
        `/nests/${id}/dialogs?page=${page}&limit=${limit}`,
        {
          conversationStarter: name,
          guestIdentifier: identifier,
          dateFrom,
          dateTo,
        },
        '&',
      ),
    ),
  getInteractions: (nestId: string, dialogId: string, page: number) => {
    return axios.get(
      `/nests/${nestId}/dialogs/${dialogId}/interactions?limit=50&page=${page}`,
    )
  },
  getDialog: (nestId: string, dialogId: string) => {
    return axios.get(`/nests/${nestId}/dialogs/${dialogId}`)
  },
  getTrainingDialog: (nestId: string) => {
    return axios.get(`/nests/${nestId}/dialogs/training`)
  },
  askQuestion: (data: any) => {
    return axios.post('/chatbot/ask', data)
  },
}
