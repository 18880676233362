export default (state: any) => {
  const users = () => {
    return state.users.items
  }

  const usersCount = () => {
    return state.users.count
  }

  const user = () => {
    return state.user
  }

  const selected = () => {
    return state.selected
  }

  const fetched = () => {
    return state.fetched
  }

  return {
    users,
    usersCount,
    user,
    selected,
    fetched,
  }
}
