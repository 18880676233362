<template>
  <v-card class="px-8 py-6 rounded-lg h-100" flat>
    <v-card-title class="pa-0">{{ title }}</v-card-title>
    <v-divider class="border-opacity-100 my-4" />
    <v-card-text class="pa-0">
      <v-row v-for="(value, key) in data" :key="key">
        <v-col cols="3" class="text-gray-400">{{ formatKey(key) }}</v-col>
        <v-col cols="9">{{ value }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
  defineProps({
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  })

  const formatKey = (key: any) => {
    return key
      .split(/(?=[A-Z])/)
      .join(' ')
      .toLowerCase()
      .replace(/^[a-z]/, (char: any) => char.toUpperCase())
  }
</script>

<style scoped lang="scss"></style>
