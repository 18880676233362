import nests from '@/api/nests/nests'
import { toast } from 'vue3-toastify'

export default (state: any, mutations: any) => {
  const fetchDialog = async (
    organizationId: string,
    nestId: string,
    dialogId: string,
  ) => {
    try {
      const { data } = await nests.fetchDialog(organizationId, nestId, dialogId)
      mutations.setDialog(data)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const fetchTrainingDialog = async (
    organizationId: string,
    nestId: string,
  ) => {
    try {
      const { data } = await nests.fetchTrainingDialog(organizationId, nestId)
      mutations.setTrainingDialog(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const createDialog = async (
    organizationId: string,
    nestId: string,
    data: any,
  ) => {
    try {
      const payload = await nests.createDialog(organizationId, nestId, data)
      mutations.setDialog(payload.data)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const fetchDialogs = async (nestId: string) => {
    try {
      const { data } = await nests.getDialogs(nestId)
      mutations.setDialogs(data)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const fetchHistoryDialogs = async ({
    orgId,
    nestId,
    page,
    limit,
    name = '',
    identifier = '',
    dateFrom = '',
    dateTo = '',
  }: any) => {
    try {
      const { data } = await nests.fetchHistoryDialogs(
        orgId,
        nestId,
        page,
        limit,
        name,
        identifier,
        dateFrom,
        dateTo,
      )
      mutations.setHistoryDialogs(data)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const fetchInteractions = async (
    orgId: string,
    nestId: string,
    dialogId: string,
    page: number,
  ) => {
    try {
      const { data } = await nests.fetchInteractions(
        orgId,
        nestId,
        dialogId,
        page,
      )
      mutations.setInteractions(data)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  return {
    fetchDialog,
    fetchTrainingDialog,
    fetchHistoryDialogs,
    createDialog,
    fetchDialogs,
    fetchInteractions,
  }
}
