<template>
  <v-main class="admin-main">
    <v-container class="page-wrapper d-flex flex-column">
      <organizations-table />
    </v-container>
  </v-main>
</template>

<script lang="ts" setup>
  import { useOrganizationStore } from '@/stores/organizations'
  import OrganizationsTable from '@/components/admin/organizations/OrganizationsTable.vue'

  const organizationStore = useOrganizationStore()

  organizationStore.actions.fetchOrganizations()
  // createdAt:"2023-11-22T09:41:17.127Z"
  // fieldOfActivity:"OTHER"
  // isDeleted:false
  // name:"my_organization"
  // organizationId:1
  // owner:4
  // updatedAt:"2023-11-22T09:41:17.127Z"
</script>

<style lang="scss" scoped></style>
