<template>
  <div class="daily-schedule">
    <span class="daily-schedule__title">{{
      $t('crawler-dialog.second-step.executions.daily.text')
    }}</span>
    <div class="daily-schedule__time">
      <ChCombobox
        class="daily-schedule__input"
        v-model="hour"
        :options="hours"
      />
      <ChCombobox
        class="daily-schedule__input"
        v-model="min"
        :options="minutes"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ChCombobox } from '@chatlyncom/chatlyn-ui-components'

  const hour = defineModel('hour', {
    default: '10',
  })
  const min = defineModel('min', {
    default: '00',
  })

  const hours = Array.from({ length: 24 }, (_, i) => ({
    name: i < 10 ? `0${i}` : `${i}`,
    value: i < 10 ? `0${i}` : `${i}`,
  }))

  const minutes = Array.from({ length: 60 }, (_, i) => ({
    name: i < 10 ? `0${i}` : `${i}`,
    value: i < 10 ? `0${i}` : `${i}`,
  }))
</script>

<style scoped lang="scss">
  .daily-schedule {
    @apply flex flex-wrap w-full;

    &__title {
      @apply w-full mb-2 dark:text-gray-300;
    }

    &__time {
      @apply w-full flex;
    }

    &__input {
      @apply flex-1 dark:text-gray-400;

      &:first-of-type {
        @apply mr-4;
      }
    }
  }
</style>
