<template>
  <main class="main">
    <div class="container flex flex-wrap content-start">
      <InfoBox
        :title="resourceTitle"
        :description="$t('nest.info-box.description')"
        img-name="product-review"
        info-box-name="resourceInfoBox"
        route-name="resources"
      >
        <template #default>
          <div class="flex mt-4 gap-2">
            <ChButton
              prepend-icon="arrow-upload"
              @click="toggleResourceDialog"
              >{{ $t('nest.info-box.upload') }}</ChButton
            >
            <ChButton
              prepend-icon="text-edit-style"
              @click="toggleResourceEditorDialog"
              >{{ $t('nest.info-box.editor') }}</ChButton
            >
            <ChButton
              v-if="webScrapingEnabled"
              prepend-icon="globe-add"
              @click="toggleWebCrawlerDialog"
              >{{ $t('web-crawler-dialog.btn') }}</ChButton
            >
          </div>
        </template>
      </InfoBox>
      <ul v-if="records.length > 0" class="w-full space-y-2">
        <li
          v-for="resource in records"
          :key="resource.dataSourceId || resources.webScraperId"
        >
          <ResourceCard :resource="resource" />
        </li>
      </ul>
      <EmptyState v-else img-name="NoDocuments" :title="$t('nest.empty')" />
      <ResourcePagination
        v-if="resources.length > 0"
        :pagination-page="paginationPage"
        :pagination-limit="paginationLimit"
        @update:pagination-page="setPage"
        @update:pagination-limit="setLimit"
      />
    </div>
  </main>
</template>

<script lang="ts" setup>
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { useResourceStore } from '@/stores/resources'
  import { useNestStore } from '@/stores/nests'
  import { useGlobalStore } from '@/stores/global'
  import ResourceCard from '@/components/app/resources/ResourceCard.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import ResourcePagination from '@/components/app/resources/ResourcePagination.vue'
  import { useRouteParams } from '@/composables/route-params'
  import { useCrawlerStore } from '@/stores/crawler'
  import { ChButton } from '@chatlyncom/chatlyn-ui-components'
  import InfoBox from '@/components/app/global/InfoBox.vue'

  const { t } = useI18n()
  const route = useRoute()
  const globalStore = useGlobalStore()
  const resourceStore = useResourceStore()
  const nestStore = useNestStore()
  const crawlerStore = useCrawlerStore()

  const { orgId, nestId } = useRouteParams()

  const interval = ref<any>(null)
  const paginationPage = ref(1)
  const paginationLimit = ref(
    parseInt(localStorage.getItem('resourcePerPage') || '50'),
  )

  const resources = computed(() => resourceStore.getters.resources())
  const webScrapers = computed(() => crawlerStore.getters.webScrapers())
  const records = computed(() => [...resources.value, ...webScrapers.value])
  const featureFlags = computed(() => crawlerStore.getters.featureFlags())

  const webScrapingEnabled = computed(() =>
    featureFlags.value.some((flag: any) => flag.value === 'web-scraping'),
  )

  const resourceTitle = computed(() => {
    const resourceCount = records.value.length

    return resourceCount > 0
      ? `${resourceCount} ${
          resourceCount > 1
            ? t('nest.info-box.title-plural')
            : t('nest.info-box.title-single')
        }`
      : t('nest.info-box.title-empty')
  })

  onMounted(() => {
    fetchData()

    interval.value = setInterval(() => {
      const hasInProgressOrPending = resources.value.some(
        (resource: any) =>
          resource.status === 'IN_PROGRESS' || resource.status === 'PENDING',
      )

      if (hasInProgressOrPending) {
        fetchData()
      }
    }, 10000)
  })

  onUnmounted(() => {
    clearInterval(interval.value)
  })

  const fetchData = async () => {
    await nestStore.actions.fetchNest(orgId.value, nestId.value)
    await crawlerStore.actions.fetchFeatureFlags(orgId.value)
    await resourceStore.actions.fetchResources(
      orgId.value,
      nestId.value,
      paginationPage.value,
      paginationLimit.value,
    )
    webScrapingEnabled.value &&
      (await crawlerStore.actions.fetchWebScrapers(orgId.value, nestId.value))
  }

  const toggleResourceDialog = () =>
    globalStore.mutations.toggleResourceDialog()

  const toggleWebCrawlerDialog = () =>
    globalStore.mutations.toggleWebCrawlerDialog()

  const toggleResourceEditorDialog = () => {
    globalStore.mutations.toggleResourceEditorDialog()
    resourceStore.mutations.setResource(null)
  }

  const setPage = (page: number) => {
    paginationPage.value = page
    fetchData()
  }

  const setLimit = (limit: number) => {
    paginationLimit.value = limit
    fetchData()
  }

  watch(
    () => [route.params.orgId, route.params.nestId],
    () => {
      if (
        route.name === 'nest' ||
        route.name === 'history' ||
        route.name === 'testing' ||
        route.name === 'configuration' ||
        route.name === 'training'
      ) {
        fetchData()
      }
    },
  )
</script>

<style lang="scss" scoped></style>
