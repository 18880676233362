export default (state: any) => {
  const setItem = (payload: any) => {
    state.item = payload
  }

  const setAnswers = (payload: any) => {
    state.answers = payload
  }

  const setQuestion = (payload: any) => {
    state.question = payload
  }

  const addAnswer = (payload: any) => {
    if (!state.answers) state.answers = []
    state.answers.push(payload)
  }

  const setSelectedAnswer = (payload: any) => {
    state.selectedAnswer = payload
  }

  return {
    setItem,
    setAnswers,
    setQuestion,
    addAnswer,
    setSelectedAnswer,
  }
}
