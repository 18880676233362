<template>
  <v-main class="admin-main">
    <v-container class="page-wrapper d-flex flex-column">
      <users-table />
    </v-container>
  </v-main>
</template>

<script lang="ts" setup>
  import UsersTable from '@/components/admin/users/UsersTable.vue'
  import { useUserStore } from '@/stores/user'

  const userStore = useUserStore()

  userStore.actions.getUsers()
</script>

<style lang="scss" scoped></style>
