<template>
  <table class="table">
    <thead class="table__head head">
      <tr class="head__row">
        <th v-for="col in cols" :key="col.field" class="head__cell">
          {{ col.title }}
        </th>
      </tr>
    </thead>
    <tbody class="table__body body">
      <tr v-for="dialog in dialogs" :key="dialog.dialogId" class="body__row">
        <td class="body__cell" @click="openInteraction(dialog)">
          <div class="body__wrapper">
            {{ dialog.conversationStarter || '---' }}
          </div>
        </td>
        <td class="body__cell">{{ dialog.guestIdentifier || '---' }}</td>
        <td class="body__cell">{{ dialog.totalInteractions }}</td>
        <td class="body__cell">
          <div class="body__last-interaction">
            <span class="body__date">
              {{ moment(dialog.lastInteraction).format('DD.MM.YYYY') || '---' }}
            </span>
            <span class="body__time">
              <ChIcon icon="clock-outlined" class="body__icon" :size="12" />
              {{ moment(dialog.lastInteraction).format('HH:mm') }}
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import moment from 'moment'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useI18n } from 'vue-i18n'
  import { ChIcon } from '@chatlyncom/chatlyn-ui-components'

  const { t } = useI18n()
  const dialogStore = useDialogsStore()

  const emits = defineEmits(['open-interactions'])

  const cols = ref([
    {
      field: 'conversationStarter',
      title: t('history.content.table.starter'),
      width: '600px',
    },
    { field: 'guestIdentifier', title: t('history.content.table.identifier') },
    {
      field: 'totalInteractions',
      title: t('history.content.table.Interactions'),
    },
    { field: 'lastInteraction', title: t('history.content.table.last-update') },
  ])

  const dialogs = computed<any>(() => dialogStore.getters.historyDialogs())

  const openInteraction = (item: any) => {
    emits('open-interactions', item)
  }
</script>

<style scoped lang="scss">
  .table {
    @apply w-full rounded text-sm table-fixed;
  }

  .head {
    &__row {
      @apply flex bg-gray-100 rounded-t dark:bg-gray-800 w-full border border-gray-200 dark:border-gray-700;
    }

    &__cell {
      @apply font-semibold text-gray-400 w-40 text-left p-4;

      &:first-of-type {
        @apply flex-1;
      }

      &:nth-of-type(2) {
        @apply w-60;
      }
    }
  }

  .body {
    &__row {
      @apply flex items-center transition-all hover:bg-gray-100 dark:hover:bg-gray-800 border-x border-b border-gray-200 dark:border-gray-700;
    }

    &__cell {
      @apply w-40 p-4;

      &:first-of-type {
        @apply flex-1 cursor-pointer;
      }

      &:nth-of-type(2) {
        @apply w-60 text-gray-400;
      }
    }

    &__wrapper {
      @apply w-full break-all;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__time {
      @apply text-gray-400 text-xs flex items-center;
    }

    &__icon {
      @apply mr-1;
    }
  }
</style>
