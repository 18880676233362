import axios from 'axios'

export default {
  getMe: () => {
    return axios.get('/users/me')
  },

  getUsers: () => {
    return axios.get('/users')
  },

  getUser: (id: string) => {
    return axios.get('/users/' + id)
  },

  updateUser: (id: string, data: any) => {
    return axios.patch('/users/' + id, data)
  },

  changeUserPassword: (id: string, data: any) => {
    return axios.patch(`/users/${id}/password`, data)
  },

  suspendUser: (id: string) => {
    return axios.patch(`/users/${id}/suspend`)
  },

  createUser: (data: any) => {
    return axios.post(`/users`, data)
  },
}
