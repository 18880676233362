<template>
  <div class="crawler">
    <div class="crawler__config">
      <BaseConfig
        v-model:title="scraper.title"
        v-model:depth="scraper.crawlingDepth"
        v-model:selectors="scraper.crawlingSelectors"
        :main-url="scraper.mainURL"
      />
      <ScheduleConfig
        :refresh-interval="scraper.refreshInterval"
        @update="setRefreshInterval"
      />
    </div>
    <ChButton
      class="crawler-config__btn"
      :disabled="!changed"
      prepend-icon="save"
      @click="save"
      >{{ $t('crawler-view.configuration.btn.save') }}</ChButton
    >
  </div>
</template>

<script setup lang="ts">
  import BaseConfig from '@/components/app/crawler/view/config/BaseConfig.vue'
  import ScheduleConfig from '@/components/app/crawler/view/config/ScheduleConfig.vue'
  import { useCrawlerStore } from '@/stores/crawler'
  import { computed, reactive, ref, watch } from 'vue'
  import { ChButton } from '@chatlyncom/chatlyn-ui-components'
  import { useRouteParams } from '@/composables/route-params'

  const { params, orgId, nestId } = useRouteParams()
  const crawlerStore = useCrawlerStore()

  const scraper = reactive({
    crawlingDepth: 0,
    crawlingSelectors: '',
    createdAt: '',
    mainURL: '',
    nest: 0,
    refreshInterval: '',
    scrapingSelectors: '',
    title: '',
    updatedAt: '',
    webScraperId: 0,
  })
  const copiedScraper = reactive({
    crawlingDepth: 0,
    crawlingSelectors: '',
    createdAt: '',
    mainURL: '',
    nest: 0,
    refreshInterval: '',
    scrapingSelectors: '',
    title: '',
    updatedAt: '',
    webScraperId: 0,
  })
  const changed = ref(false)
  const storeScraper = computed(() => crawlerStore.getters.scraper())

  const setRefreshInterval = (value: string) => {
    scraper.refreshInterval = value
  }

  const save = () => {
    const body = JSON.parse(JSON.stringify(scraper))
    if (body.crawlingSelectors === '') {
      delete body.crawlingSelectors
    }
    crawlerStore.actions.updateWebScraper(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      body,
    )
  }

  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 == null ||
      obj2 == null
    )
      return false

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false
    }

    return true
  }

  const setData = (value: any) => {
    scraper.crawlingDepth = value.crawlingDepth
    copiedScraper.crawlingDepth = value.crawlingDepth
    scraper.crawlingSelectors = value.crawlingSelectors
    copiedScraper.crawlingSelectors = value.crawlingSelectors
    scraper.createdAt = value.createdAt
    copiedScraper.createdAt = value.createdAt
    scraper.mainURL = value.mainURL
    copiedScraper.mainURL = value.mainURL
    scraper.nest = value.nest
    copiedScraper.nest = value.nest
    scraper.refreshInterval = value.refreshInterval
    copiedScraper.refreshInterval = value.refreshInterval
    if (typeof value.scrapingSelectors === 'string') {
      scraper.scrapingSelectors = value.scrapingSelectors
      copiedScraper.scrapingSelectors = value.scrapingSelectors
    }
    scraper.title = value.title
    copiedScraper.title = value.title
    scraper.updatedAt = value.updatedAt
    copiedScraper.updatedAt = value.updatedAt
    scraper.webScraperId = value.webScraperId
    copiedScraper.webScraperId = value.webScraperId
  }

  watch(
    storeScraper,
    (value) => {
      if (value) {
        setData(value)
      }
    },
    { immediate: true },
  )

  watch(
    scraper,
    () => {
      changed.value = !deepEqual(scraper, copiedScraper)
    },
    { deep: true },
  )
</script>

<style scoped lang="scss">
  .crawler {
    @apply flex flex-col items-end w-full;

    &__config {
      @apply flex flex-wrap w-full mb-4;
    }
  }
</style>
