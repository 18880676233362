<template>
  <div class="table-wrapper d-flex flex-column bg-whiteBg pa-8 rounded-lg">
    <div
      class="table-wrapper__filter mb-8 d-flex align-center justify-space-between"
    >
      <v-text-field
        class="w-50 flex-0-0"
        variant="outlined"
        color="textSecondary"
        placeholder="Search for account names or email addresses ..."
        hide-details
      />
      <v-btn color="primary" @click="toggleCreateOrganizationDialog"
        >Create</v-btn
      >
    </div>
    <v-table class="text-body-1">
      <thead>
        <tr>
          <th class="text-left text-gray-400">Name</th>
          <th class="text-left text-gray-400">Field of Activity</th>
          <th class="text-left text-gray-400">Owner Id</th>
          <th class="text-left text-gray-400">Created At</th>
          <th />
        </tr>
      </thead>
      <tbody v-if="organizations.length > 0">
        <tr
          v-for="organization in organizations"
          :key="organization.organizationId"
        >
          <td class="history-table__conversation-starter">
            <v-btn
              elevation="0"
              variant="plain"
              :to="{
                name: 'admin-organization',
                params: { id: organization.organizationId },
              }"
              >{{ organization.name || '---' }}</v-btn
            >
          </td>
          <td class="text-gray-400">
            {{ organization.fieldOfActivity || '---' }}
          </td>
          <td>{{ organization.owner || '---' }}</td>
          <td>
            <div class="date-time d-flex flex-column">
              <span class="date">{{
                moment(organization.createdAt).format('DD.MM.YYYY') || '---'
              }}</span>
              <span class="time text-gray-400 text-caption d-flex align-center">
                <v-icon class="mr-1 text-sm-caption"
                  >mdi-clock-time-four-outline</v-icon
                >{{ moment(organization.createdAt).format('HH:mm') }}</span
              >
            </div>
          </td>
          <td>
            <v-btn
              class="mr-2"
              density="compact"
              icon="mdi-pencil"
              color="info"
              variant="tonal"
              @click="editOrganization(organization)"
            />
            <v-btn
              density="compact"
              icon="mdi-trash-can-outline"
              color="error"
              variant="tonal"
              @click="deleteOrganization(organization.organizationId)"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4" class="text-center">No dialogs</td>
        </tr>
      </tbody></v-table
    >
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import moment from 'moment'
  import { useOrganizationStore } from '@/stores/organizations'
  import { useGlobalStore } from '@/stores/global'

  const globalStore = useGlobalStore()
  const organizationStore = useOrganizationStore()

  const organizations = computed(() =>
    organizationStore.getters.organizations(),
  )

  const toggleCreateOrganizationDialog = () => {
    globalStore.mutations.toggleCreateOrganizationDialog()
  }

  const editOrganization = (organization: any) => {
    organizationStore.mutations.setCurrent(organization)
    globalStore.mutations.toggleEditOrganizationDialog()
  }

  const deleteOrganization = (orgId: number) => {
    organizationStore.actions.deleteOrganization(orgId.toString())
  }
</script>

<style scoped lang="scss"></style>
