<template>
  <div
    class="form bg-white dark:bg-gray-900 mb-4 rounded border dark:border-gray-700 w-full p-4"
  >
    <h2>
      {{ $t('profile.content.api-key.title') }}
    </h2>
    <div class="flex items-end mt-2 input-group">
      <ChInput :model-value="currentApiKey" class="flex-1" readonly>
        <template #append>
          <ChButton
            icon="copy"
            :variant="ButtonVariant.TEXT"
            :color="ColorScheme.SECONDARY"
            @click="clipboardCopySecret"
          />
          <ChButton
            icon="arrow-counterclockwise"
            :color="ColorScheme.SECONDARY"
            class="rounded-l-none"
            @click="refreshKey"
          />
        </template>
      </ChInput>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    ButtonVariant,
    ChButton,
    ChInput,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'
  import { toast } from 'vue3-toastify'
  import { useI18n } from 'vue-i18n'
  import { computed } from 'vue'

  const { t } = useI18n()

  const { apiKey } = defineProps<{ apiKey: { hash: string } }>()

  const emit = defineEmits(['refresh-key'])

  const currentApiKey = computed(() => {
    if (!apiKey) {
      return ''
    }
    return apiKey.hash.slice(0, -5).replace(/./g, '•') + apiKey.hash.slice(-5)
  })

  const clipboardCopySecret = () => {
    navigator.clipboard.writeText(apiKey.hash)
    toast.success(t('configuration.copied'))
  }

  const refreshKey = () => {
    emit('refresh-key')
  }
</script>

<style scoped lang="scss"></style>
