<template>
  <div class="crawler-content">
    <ChInput
      v-model="title"
      class="crawler-content__input crawler-content__title"
      :label="$t('crawler-view.configuration.base.internal-title')"
    />
    <ChInput
      class="crawler-content__input crawler-content__main-url"
      :label="$t('crawler-view.configuration.base.url')"
      disabled
      :placeholder="mainUrl"
    />
    <ChInput
      v-model="depth"
      class="crawler-content__input crawler-content__url-depth"
      :type="InputType.NUMBER"
      :label="$t('crawler-view.configuration.base.depth')"
    />
    <div class="crawler-content__info info">
      <h3 class="info__title">
        {{ $t('crawler-view.configuration.base.info.title') }}
      </h3>
      <p class="info__description">
        {{ $t('crawler-view.configuration.base.info.description') }}
      </p>
    </div>
    <ChInput
      v-model="selectors"
      :info-text="$t('crawler-view.configuration.base.tooltip')"
      class="crawler-content__input crawler-content__selector"
      :label="$t('crawler-view.configuration.base.selectors')"
      placeholder="nav#main-navigation"
    />
  </div>
</template>

<script setup lang="ts">
  import { ChInput, InputType } from '@chatlyncom/chatlyn-ui-components'
  import { watch } from 'vue'

  defineProps({
    mainUrl: {
      type: String,
      required: true,
    },
  })

  const title = defineModel<string>('title')
  const depth = defineModel<number>('depth')
  const selectors = defineModel<string>('selectors')

  watch(depth, (newVal: any) => {
    if (Number(newVal) < 0) {
      depth.value = 0
    } else if (Number(newVal) > 3) {
      depth.value = 3
    } else if (!Number(newVal)) {
      depth.value = 0
    }
  })
</script>

<style scoped lang="scss">
  .crawler-content {
    @apply flex flex-wrap flex-1;

    &__input {
      @apply mb-4;
    }

    &__main-url {
      @apply w-2/3;
    }

    &__url-depth {
      @apply flex-1 ml-4;
    }

    &__btn {
      @apply ml-auto;
    }
  }

  .info {
    @apply p-2 rounded w-full mb-4 text-sm bg-woot-50 text-woot-700 dark:bg-woot-700/70 dark:text-woot-100;

    &__title {
      @apply font-semibold;
    }

    &__description {
    }
  }
</style>
