<template>
  <main class="main">
    <div class="container flex flex-wrap content-start">
      <InfoBox
        :title="$t('profile.info-box.title')"
        :description="$t('profile.info-box.description')"
        img-name="product-review"
        info-box-name="profileInfoBox"
        route-name="profile"
        show-close-icon
      />
      <ProfileInfo class="mb-8" />
      <ProfilePassword class="mb-8" />
      <ProfileApiKeys
        v-if="apiKey"
        :api-key="apiKey"
        @refresh-key="refreshKey"
      />
    </div>
  </main>
</template>

<script lang="ts" setup>
  import ProfilePassword from '@/components/app/profile/ProfilePassword.vue'
  import ProfileInfo from '@/components/app/profile/ProfileInfo.vue'
  import InfoBox from '@/components/app/global/InfoBox.vue'
  import ProfileApiKeys from '@/components/app/profile/ProfileApiKeys.vue'
  import { useOrganizationStore } from '@/stores/organizations'
  import { computed, onMounted, watch } from 'vue'
  import { toast } from 'vue3-toastify'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const organizationStore = useOrganizationStore()

  const apiKey = computed(() => organizationStore.getters.apiKey())

  const organizations = computed(() =>
    organizationStore.getters.organizations(),
  )

  const fetchOrganizationsApiKeys = async (id: string) => {
    return await organizationStore.actions.fetchOrganizationsApiKeys(id)
  }

  const manageApiKeys = async (organization: any) => {
    const apiKeys = await fetchOrganizationsApiKeys(organization.organizationId)
    if (
      !apiKeys ||
      apiKeys.length === 0 ||
      !apiKeys.find((key: any) => key.active)
    ) {
      await organizationStore.actions.createOrganizationApiKey(
        organization.organizationId,
      )
    }
  }

  const refreshKey = async () => {
    if (apiKey.value) {
      try {
        await organizationStore.actions.deleteOrganizationApiKey(
          organizations.value[0].organizationId,
          apiKey.value.apiTokenId,
        )
        await organizationStore.actions.createOrganizationApiKey(
          organizations.value[0].organizationId,
        )
        toast.success(t('profile.content.api-key.refreshed'))
      } catch (error) {
        toast.error(t('profile.content.api-key.error'))
      }
    }
  }

  watch(organizations, (newOrganizations: any[]) => {
    if (newOrganizations.length > 0) {
      manageApiKeys(newOrganizations[0])
    }
  })

  onMounted(() => {
    organizationStore.actions.fetchOrganizations()
  })
</script>

<style lang="scss" scoped></style>
