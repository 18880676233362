<template>
  <div class="table__container">
    <table ref="tableEl" class="table">
      <thead class="table__head head">
        <tr class="head__row">
          <th class="head__col">
            <ChCheckbox
              id="select-all"
              v-model="selectAll"
              @update:model-value="selectAllRows"
            />
          </th>
          <th class="head__col">
            <div class="table__wrapper">
              URL
              <div class="table__icons" @click="setSort('source')">
                <ChIcon
                  class="table__icon"
                  :class="{
                    'text-woot-500':
                      sort.orderBy === 'source' &&
                      sort.orderDirection === 'DESC',
                  }"
                  :size="8"
                  icon="caret-up"
                />
                <ChIcon
                  class="table__icon"
                  :class="{
                    'text-woot-500':
                      sort.orderBy === 'source' &&
                      sort.orderDirection === 'ASC',
                  }"
                  :size="8"
                  icon="caret-down"
                />
              </div>
            </div>
          </th>
          <th class="head__col">
            <div class="table__wrapper">
              Status
              <div class="table__icons" @click="setSort('status')">
                <ChIcon
                  class="table__icon"
                  :class="{
                    'text-woot-500':
                      sort.orderBy === 'status' &&
                      sort.orderDirection === 'DESC',
                  }"
                  :size="8"
                  icon="caret-up"
                />
                <ChIcon
                  class="table__icon"
                  :class="{
                    'text-woot-500':
                      sort.orderBy === 'status' &&
                      sort.orderDirection === 'ASC',
                  }"
                  :size="8"
                  icon="caret-down"
                />
              </div>
            </div>
          </th>
          <th class="head__col">
            <div class="table__wrapper">
              Last updated
              <div class="table__icons" @click="setSort('updatedAt')">
                <ChIcon
                  class="table__icon"
                  :class="{
                    'text-woot-500':
                      sort.orderBy === 'updatedAt' &&
                      sort.orderDirection === 'DESC',
                  }"
                  :size="8"
                  icon="caret-up"
                />
                <ChIcon
                  class="table__icon"
                  :class="{
                    'text-woot-500':
                      sort.orderBy === 'updatedAt' &&
                      sort.orderDirection === 'ASC',
                  }"
                  :size="8"
                  icon="caret-down"
                />
              </div>
            </div>
          </th>
          <th class="head__col"></th>
        </tr>
      </thead>
      <TransitionGroup name="fade" tag="tbody" class="table__body">
        <tr v-for="row in rows" :key="row.webpageId" class="table__row">
          <td class="table__cell">
            <ChCheckbox
              id="select-all"
              :model-value="isSelected(row.webpageId)"
              @update:model-value="toggleSelectRow(row.webpageId)"
            />
          </td>
          <td class="table__cell">{{ formatUrl(row.dataSource.source) }}</td>
          <td class="table__cell">
            <div
              class="w-2 h-2 rounded-full mr-1"
              :class="statusColor(row.dataSource.status)"
            ></div>
            <span class="status__text">{{
              statusText(row.dataSource.status)
            }}</span>
          </td>
          <td class="table__cell">
            {{ formatDate(row.dataSource.updatedAt) }}
          </td>
          <td class="table__cell">
            <ChMenu :position="BasicElPosition.BOTTOM">
              <template #activator>
                <ChButton
                  icon="more-horizontal"
                  :variant="ButtonVariant.CLEAR"
                  :size="ButtonSize.SM"
                />
              </template>
              <ChButton
                :size="ButtonSize.SM"
                prepend-icon="play-outlined"
                :variant="ButtonVariant.TEXT"
                :color="ColorScheme.SECONDARY"
                @click="rerun(row)"
                >{{ $t('crawler-view.urls.table.menu.rerun') }}</ChButton
              >
              <ChButton
                :size="ButtonSize.SM"
                prepend-icon="delete"
                :variant="ButtonVariant.TEXT"
                :color="ColorScheme.ALERT"
                @click="deleteRow(row)"
                >{{ $t('crawler-view.urls.table.menu.delete') }}</ChButton
              >
            </ChMenu>
          </td>
        </tr>
      </TransitionGroup>
      <tfoot>
        <tr>
          <td>
            <div class="table__pagination">
              <ChButton
                :size="ButtonSize.SM"
                icon="chevron-double-left"
                :variant="ButtonVariant.CLEAR"
                :color="ColorScheme.SECONDARY"
                :disabled="Number(query.page) === 1"
                @click="firstPage"
              />
              <ChButton
                :size="ButtonSize.SM"
                icon="chevron-left"
                :variant="ButtonVariant.CLEAR"
                :color="ColorScheme.SECONDARY"
                :disabled="Number(query.page) === 1"
                @click="prevPage"
              />
              <span class="table__page">{{ Number(query.page) }}</span>
              <ChButton
                :size="ButtonSize.SM"
                icon="chevron-right"
                :variant="ButtonVariant.CLEAR"
                :color="ColorScheme.SECONDARY"
                :disabled="Number(query.page) === Math.ceil(props.count / 50)"
                @click="nextPage"
              />
              <ChButton
                :size="ButtonSize.SM"
                icon="chevron-double-right"
                :variant="ButtonVariant.CLEAR"
                :color="ColorScheme.SECONDARY"
                :disabled="Number(query.page) === Math.ceil(props.count / 50)"
                @click="lastPage"
              />
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script setup lang="ts">
  import {
    BasicElPosition,
    ButtonSize,
    ButtonVariant,
    ChButton,
    ChCheckbox,
    ChIcon,
    ChMenu,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'
  import { computed, type PropType, reactive, ref } from 'vue'
  import type { Webpage } from '@/types/interfaces'
  import moment from 'moment'
  import { useRouteParams } from '@/composables/route-params'
  import { useCrawlerStore } from '@/stores/crawler'

  interface Sort {
    orderBy: string
    orderDirection: string
  }

  const { query } = useRouteParams()
  const crawlerStore = useCrawlerStore()

  const props = defineProps({
    rows: {
      type: Array as PropType<Webpage[]>,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
  })

  const emit = defineEmits(['change-page', 'select', 'rerun', 'delete', 'sort'])

  const sort: Sort = reactive({
    orderBy: '',
    orderDirection: '',
  })
  const selectAll = ref(false)
  const selectedRows = ref<number[]>([])

  const scraper = computed(() => crawlerStore.getters.scraper())

  const setSort = (orderBy: string) => {
    if (sort.orderBy === orderBy) {
      sort.orderDirection = sort.orderDirection === 'ASC' ? 'DESC' : 'ASC'
    } else {
      sort.orderBy = orderBy
      sort.orderDirection = 'ASC'
    }

    emit('sort', sort)
  }

  const statusText = (status: string) => {
    if (status === 'DONE') {
      return 'Live'
    } else if (status === 'IN_PROGRESS' || status === 'PENDING') {
      return 'Pending'
    } else if (status === 'DRAFT') {
      return 'Draft'
    } else {
      return 'Failed'
    }
  }

  const statusColor = (status: string) => {
    if (status === 'DONE') {
      return 'bg-green-500'
    } else if (status === 'IN_PROGRESS' || status === 'PENDING') {
      return 'bg-yellow-500 pulse'
    } else if (status === 'DRAFT') {
      return 'bg-gray-400'
    } else {
      return 'bg-red-500'
    }
  }

  const formatDate = (date: string) => {
    return moment(date).format('DD.MM.YYYY - HH:mm')
  }

  const formatUrl = (url: string) => {
    return scraper.value
      ? url.replace(scraper.value.mainURL, '').charAt(0) !== '/'
        ? '/' + url.replace(scraper.value.mainURL, '')
        : url.replace(scraper.value.mainURL, '')
      : url
  }

  const selectAllRows = () => {
    if (selectAll.value) {
      selectedRows.value = props.rows.map((row) => row.webpageId)
    } else {
      selectedRows.value = []
    }

    emit('select', selectedRows.value)
  }

  const isSelected = (id: number) => {
    return selectedRows.value.includes(id)
  }

  const toggleSelectRow = (id: number) => {
    if (selectedRows.value.includes(id)) {
      selectedRows.value = selectedRows.value.filter((rowId) => rowId !== id)
    } else {
      selectedRows.value = [...selectedRows.value, id]
    }

    emit('select', selectedRows.value)
  }

  const rerun = (row: Webpage) => {
    emit('rerun', row)
  }

  const deleteRow = (row: Webpage) => {
    emit('delete', row.webpageId)
  }

  const firstPage = () => {
    emit('change-page', 1)
  }

  const prevPage = () => {
    emit('change-page', Number(query.value.page) - 1)
  }

  const nextPage = () => {
    emit('change-page', Number(query.value.page) + 1)
  }

  const lastPage = () => {
    emit('change-page', Math.ceil(props.count / 3))
  }
</script>

<style scoped lang="scss">
  .table {
    @apply w-full;

    &__wrapper {
      @apply flex items-center;
    }

    &__icons {
      @apply flex flex-col ml-1 cursor-pointer text-gray-300;
    }

    &__row {
      @apply flex w-full border-b border-gray-200 border-x dark:border-gray-700;
    }

    &__cell {
      @apply flex items-center p-2 text-xs;

      &:first-of-type,
      &:last-of-type {
        @apply w-10 justify-center;
      }

      &:nth-of-type(2) {
        @apply flex-1 text-sm truncate;
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        @apply w-40;
      }
    }

    &__pagination {
      @apply flex items-center justify-end bg-gray-50 p-2 border-b border-x border-gray-200 dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300;
    }

    &__page {
      @apply px-2 text-gray-500;
    }
  }

  .head {
    @apply rounded-lg border border-gray-200 rounded-t-lg bg-gray-50 dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300;

    &__row {
      @apply flex w-full;
    }

    &__col {
      @apply flex items-center text-xs font-light p-2;

      &:first-of-type,
      &:last-of-type {
        @apply w-10 justify-center;
      }

      &:nth-of-type(2) {
        @apply flex-1;
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        @apply w-40;
      }
    }
  }

  .fade-move,
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
  }

  .fade-leave-active {
    position: absolute;
  }
</style>
