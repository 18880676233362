import { toast } from 'vue3-toastify'
// import organizations from '@/api/nests/organizations'
import members from '@/api/nests/members'

export default (mutations: any) => {
  const fetchMembers = async (organizationId: string) => {
    try {
      const { data } = await members.fetchMembers(organizationId)
      mutations.setMembers(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const inviteMember = async (organizationId: string, data: any) => {
    try {
      await members.inviteMember(organizationId, data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const removeMember = async (organizationId: string, memberId: string) => {
    try {
      await members.removeMember(organizationId, memberId)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const updateMemberRole = async (
    organizationId: string,
    memberId: string,
    data: any,
  ) => {
    try {
      await members.updateMemberRole(organizationId, memberId, data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  return {
    fetchMembers,
    inviteMember,
    removeMember,
    updateMemberRole,
  }
}
