<template>
  <li class="interaction group cursor-default">
    <h4 class="interaction__title">{{ interaction.question.text }}</h4>
    <div class="interaction__content relative">
      <img
        :src="imgLoader('logo-svg', 'svg')"
        alt="answers logo"
        class="history__logo bg-violet-50 dark:bg-gray-950/70 rounded p-2 mt-2 w-9 h-9 mx-4"
      />
      <div class="flex flex-col items-start flex-1 py-4">
        <p
          class="max-w-3xl interaction__answer text-sm mb-4 group-hover:mb-8 dark:text-white transition-all duration-200 pr-4"
        >
          {{ showAnswer }}
        </p>
        <ChButton
          class="absolute bottom-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          :size="ButtonSize.SM"
          @click="improveAnswer"
        >
          {{ $t('history.content.interactions.improve') }}
        </ChButton>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
  import { computed, type PropType } from 'vue'
  import imgLoader from '@/utils/img-loader'
  import type { Interaction } from '@/types/interfaces'
  import { ButtonSize, ChButton } from '@chatlyncom/chatlyn-ui-components'

  const props = defineProps({
    interaction: {
      type: Object as PropType<Interaction>,
      required: true,
    },
  })

  const emits = defineEmits(['improve'])

  const improveAnswer = () => {
    emits('improve', props.interaction)
  }

  const parseTextValue = (text: string): any => {
    try {
      const parsed = JSON.parse(text)
      if (typeof parsed === 'object' && parsed !== null) {
        return parsed.answer
      }
    } catch (e) {
      // Not a valid JSON string, return the original text
    }
    return text
  }

  const showAnswer = computed(() => {
    return parseTextValue(props.interaction.answers[0].text) || '---'
  })
</script>

<style lang="scss" scoped>
  .interaction {
    &__title {
      @apply bg-gray-100 dark:bg-gray-950/70 dark:text-white px-16 py-4 text-sm;
    }
    &__content {
      @apply flex items-start;
    }
  }
</style>
