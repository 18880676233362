export default (state: any) => {
  const resources = () => {
    return state.resources.items
  }

  const resource = () => {
    return state.resource
  }

  return {
    resources,
    resource,
  }
}
