<template>
  <app-layout />
</template>

<script lang="ts" setup>
  import AppLayout from '@/layouts/AppLayout.vue'
  import { useI18n } from 'vue-i18n'
  import { onMounted } from 'vue'
  import { setFrame } from '@/utils/frame'

  const PARAMS_LIST = ['lang', 'accountId', 'theme']

  interface ParamsObject {
    [key: string]: string | number | null
  }

  const { locale } = useI18n()

  onMounted(() => {
    setDataFromParams()
  })

  const getDataFromParams = (paramNames: string[]) => {
    const params = new URL(location.href).searchParams
    const paramsMap: ParamsObject = {}
    paramNames.forEach((param) => {
      paramsMap[param] = params.get(param)
    })
    return paramsMap
  }

  const checkAllPropertiesNull = (obj: { [key: string]: any }): boolean => {
    return Object.values(obj).every((val) => val === null)
  }

  const setDataFromParams = () => {
    const dataParams = getDataFromParams(PARAMS_LIST)
    const isParamsExist = checkAllPropertiesNull(dataParams)

    if (!isParamsExist) {
      locale.value = (dataParams.lang as string) || 'en'

      setFrame(true)
    } else {
      setFrame(false)
    }
  }
</script>

<style lang="scss" scoped></style>
