<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ active: index === currentTab }"
        class="tabs__item"
        @click="selectTab(index)"
      >
        {{ item[label] }}
      </li>
    </ul>
    <ul class="tabs__list">
      <slot />
    </ul>
  </div>
</template>

<script setup lang="ts">
  import type { PropType } from 'vue'

  defineProps({
    items: {
      type: Array as PropType<any>,
      required: true,
    },
    label: {
      type: String,
      default: 'label',
    },
    currentTab: {
      type: Number,
      required: true,
    },
  })

  const emits = defineEmits(['select-tab'])

  const selectTab = (index: number) => {
    emits('select-tab', index)
  }
</script>

<style scoped lang="scss">
  .tabs {
    &__header {
      @apply flex w-full mb-4 border-b border-b-gray-100 dark:border-b-gray-700;
    }

    &__item {
      @apply text-sm font-light text-center p-2 cursor-pointer border-b-2 border-b-transparent;

      &.active {
        @apply border-b-woot-500;
      }
    }
  }
</style>
