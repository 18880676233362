export const addParams = (url: string, data: any, symbol = '?') => {
  const params: string[] = []

  Object.keys(data).forEach((key: string) => {
    if (data[key] !== '') {
      params.push(`${key}=${data[key]}`)
    }
  })

  if (params.length > 0) {
    url += symbol + params.join('&')
  }

  return url
}
