export default (state: any) => {
  const organizationsApiKeys = () => {
    return state.organizationsApiKeys.items
  }

  const apiKey = () => {
    return state.apiKey
  }

  const organizations = () => {
    return state.organizations.items
  }

  const organization = () => {
    return state.organization
  }

  const current = () => {
    return state.current
  }

  return {
    organizationsApiKeys,
    apiKey,
    organizations,
    organization,
    current,
  }
}
