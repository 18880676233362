<template>
  <div class="schedule">
    <h3 class="schedule__title">
      {{ t('crawler-view.configuration.schedule.title') }}
    </h3>
    <ChCombobox class="schedule__input" v-model="type" :options="types" />
    <DefaultSchedule v-if="type === 0" />
    <DailySchedule
      v-if="type === 1"
      v-model:hour="timeHour"
      v-model:min="timeMin"
    />
    <WeeklySchedule
      v-if="type === 2"
      v-model:days="days"
      v-model:hour="timeHour"
      v-model:min="timeMin"
    />
    <CustomSchedule v-if="type === 3" v-model="customCron" />
  </div>
</template>

<script setup lang="ts">
  import { ChCombobox } from '@chatlyncom/chatlyn-ui-components'
  import CustomSchedule from '@/components/app/crawler/dialog/schedules/CustomSchedule.vue'
  import DailySchedule from '@/components/app/crawler/dialog/schedules/DailySchedule.vue'
  import DefaultSchedule from '@/components/app/crawler/dialog/schedules/DefaultSchedule.vue'
  import WeeklySchedule from '@/components/app/crawler/dialog/schedules/WeeklySchedule.vue'
  import { onMounted, ref, watch } from 'vue'
  import { useCron } from '@/composables/useCron'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  const props = defineProps({
    refreshInterval: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(['update'])

  const cronString = ref<string>('* * * * *')
  const type = ref<number>(3)
  const days = ref<string[]>([])
  const timeHour = ref<string>('11')
  const timeMin = ref<string>('00')
  const customCron = ref<string>('* * * * *')

  const types = [
    {
      name: t('crawler-view.configuration.schedule.types.no-schedule'),
      value: 0,
    },
    {
      name: t('crawler-view.configuration.schedule.types.daily'),
      value: 1,
    },
    {
      name: t('crawler-view.configuration.schedule.types.weekly'),
      value: 2,
    },
    {
      name: t('crawler-view.configuration.schedule.types.custom'),
      value: 3,
    },
  ]

  const unparseCronString = () => {
    unparseCronDaysOfWeek()
    unparseCronHour()
    unparseCronMin()
  }

  const unparseCronDaysOfWeek = () => {
    const daysOfWeekParts = cronString.value.split(' ')[4]

    if (daysOfWeekParts !== '*') {
      const daysOfWeek: string[] = []

      daysOfWeekParts.split(',').forEach((part: string) => {
        if (part.includes('-')) {
          const [start, end] = part.split('-').map(Number)

          for (let day = start; day <= end; day++) {
            daysOfWeek.push(day.toString())
          }
        } else {
          daysOfWeek.push(part)
        }
      })

      days.value = Array.from(new Set(daysOfWeek)).sort()
    }
  }

  const unparseCronHour = () => {
    const hourPart = cronString.value.split(' ')[1]

    if (hourPart !== '*') {
      timeHour.value = hourPart
    }
  }

  const unparseCronMin = () => {
    const minPart = cronString.value.split(' ')[0]

    if (minPart !== '*') {
      timeMin.value = minPart
    }
  }

  watch([days, timeHour, timeMin], () => {
    cronString.value = useCron(cronString, timeHour, timeMin, days)

    emit('update', cronString.value)
  })

  watch(customCron, () => {
    emit('update', customCron.value)
  })

  onMounted(() => {
    cronString.value = props.refreshInterval
    customCron.value = props.refreshInterval

    unparseCronString()
  })
</script>

<style scoped lang="scss">
  .schedule {
    @apply flex flex-col w-96 ml-4;

    &__title {
      @apply text-sm mb-1;
    }

    &__input {
      @apply mb-4;
    }
  }
</style>
