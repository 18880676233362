export const useValidation = () => {
  const isValidUrl = (url: string): boolean => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?((www\\.)?([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.[a-z]{2,})|((\\d{1,3}\\.){3}\\d{1,3})(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
      'i',
    )

    return urlPattern.test(url)
  }

  return {
    isValidUrl,
  }
}
