import { reactive } from 'vue'

export default reactive<any>({
  users: {
    count: 0,
    items: [],
  },
  selected: null,
  user: {},
  fetched: false,
})
