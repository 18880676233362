export default (state: any) => {
  const members = () => {
    return state.members
  }

  const member = () => {
    return state.member
  }

  const current = () => {
    return state.current
  }

  return {
    members,
    member,
    current,
  }
}
